// src/translations/i18nextConfig.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import the translation JSON files
import loadTranslations from './loadTranslations';

// Initialize i18next with the local translation resources
const initializeI18next = async () => {
  const translations = await loadTranslations();
  i18n.use(initReactI18next).init({
    resources: translations,
    lng: 'en', // Set your default language here
    fallbackLng: 'en',
    supportedLngs: ['en', 'fr'], // Add other supported languages here
    interpolation: {
      escapeValue: false, // React already protects from XSS
      format: (value, format, lng) => {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      },
    },
    debug: false, // Set to true for debugging
  });
};

initializeI18next();

// Function to change language
export const changeLanguage = (locale) => {
  i18n.changeLanguage(locale);
};

// Export the i18n instance for use in I18nextProvider
export { i18n };

// Export i18n as default
export default i18n;
