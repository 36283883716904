import useMarkdownTranslations from '../../../translations/i18nextConfig/useMarkdownTranslations';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import {
  Button,
  Box,
  Center,
  Spinner,
  Flex,
  Input,
  InputGroup,
  Text,
  FormControl,
  Link,
  Checkbox,
  Stack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getErrorMessage } from 'utils';
import groupsApi from '../../../api/groups';
import { useCurrentUserContext } from 'features/user/contexts/user-provider';

const JoinWithoutAccount = ({ redirectUser, userName, token }) => {
  const { currentGroup, fetchGroupsAndInvitations } = useCurrentGroupsContext();
  const { t, tMarkdown } = useMarkdownTranslations();
  const { login } = useCurrentUserContext();

  const [receiveNotifications, setReceiveNotifications] = useState(false);
  const [agreeWithTerms, setAgreeWithTerms] = useState(false);
  const [name, setName] = useState(userName);
  const [nameError, setNameError] = useState('');
  const [errorAgreeWithTerms, setErrorAgreeWithTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const registerInApp = async () => {
    if (!agreeWithTerms) {
      setErrorAgreeWithTerms(true);
      return;
    }
    const joinData = {
      data: {
        type: 'group-user',
        attributes: {
          accept_terms: agreeWithTerms,
          invite_token: token,
          name,
          notifications: [receiveNotifications],
        },
      },
    };
    try {
      setIsLoading(true);
      const { data, included } = await groupsApi.joinWithToken(
        currentGroup.slug,
        joinData,
      );
      login();
      await fetchGroupsAndInvitations();
      sessionStorage.setItem('first_login', 'true');
      redirectUser(data, included);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      const error = e?.data?.errors[0]?.detail;
      if (
        error &&
        e?.data?.errors[0]?.source?.pointer === '/data/attributes/username'
      ) {
        setNameError(t(getErrorMessage(error)));
      }
    }
  };

  useEffect(() => {
    setNameError('');
  }, [name]);

  useEffect(() => {
    setErrorAgreeWithTerms(false);
  }, [agreeWithTerms]);

  return (
    <Stack spacing={5}>
      <FormControl>
        <Flex className="flex-password-without-metamask">
          <Box direction="column">
            <Text className="input-form-label ">
              {t('authentication.name')}
            </Text>
          </Box>
          <Box direction="column" className="required-field">
            *
          </Box>
        </Flex>
        <Center>
          <InputGroup>
            <Flex
              className="flexColumnForErrorMessage-signup"
              direction="column"
            >
              <Input
                type="text"
                aria-labelledby="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="regular-input"
              />
              {!!nameError && (
                <Text className="errorText-signup" aria-label={nameError}>
                  {nameError}
                </Text>
              )}
            </Flex>
          </InputGroup>
        </Center>
      </FormControl>
      <Flex className="flexboxOfCheckbox-signup">
        <Checkbox
          className="checkbox-signup"
          size="lg"
          id="agree with terms"
          isChecked={agreeWithTerms}
          onChange={(e) => setAgreeWithTerms(e.target.checked)}
          aria-checked="false"
        >
          <Box className="input-form-label">
            {tMarkdown('checkbox.terms_and_conditions')}
          </Box>
        </Checkbox>
      </Flex>
      {errorAgreeWithTerms && (
        <Text
          className="errorTermsAndConditions-signup"
          aria-label={t('accessibility.authentication.accept_terms')}
        >
          {t('authentication.accept_terms')}
        </Text>
      )}
      <Flex className="flexboxOfCheckbox-signup">
        <Checkbox
          className="checkbox-signup"
          size="lg"
          id="update me"
          isChecked={receiveNotifications}
          onChange={(e) => setReceiveNotifications(e.target.checked)}
          aria-checked="false"
        >
          <Box className="input-form-label">{t('checkbox.update_me')}</Box>
        </Checkbox>
      </Flex>
      <Box>
        <Center>
          <Button className="accept-btn" onClick={() => registerInApp()}>
            {isLoading ? <Spinner /> : t('authentication.register')}
          </Button>
        </Center>
      </Box>
    </Stack>
  );
};

export default JoinWithoutAccount;
