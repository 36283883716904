import React, { useState } from 'react';
import { Grid } from '@chakra-ui/react';
import Card from 'components/GroupAsAdmin/Card';
import { pickPalette } from 'utils/colorPalette';
import { AnimatePresence, motion } from 'framer-motion';
import Pagination from 'components/Pagination';
import { useDebounce } from 'utils/debounce';
import Filters from 'components/Filters';

const cardVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
};

export default function Groups({ groups, fetchData, totalGroups }) {
  const [paginatedGroups, setPaginatedGroups] = useState([]);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('none');
  const debouncedSearch = useDebounce(search, 800);

  return (
    <>
      <Filters
        search={search}
        sortBy={sortBy}
        setSearch={setSearch}
        setSortBy={setSortBy}
      />
      <Grid className="grid-hosted-hosted-decisions-page" gap={4}>
        <AnimatePresence mode="popLayout">
          {paginatedGroups.map((group) => (
            <motion.div
              key={group.id}
              variants={cardVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              style={{ height: '100%' }}
              transition={{ duration: 0.3 }}
            >
              <Card
                key={group.id}
                group={group}
                color={pickPalette(group.title)}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </Grid>
      <Pagination
        search={debouncedSearch}
        sortBy={sortBy}
        setPaginatedGroups={setPaginatedGroups}
        groups={groups}
        totalGroups={totalGroups}
        fetchData={fetchData}
      />
    </>
  );
}
