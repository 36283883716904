import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Text,
  Flex,
  Input,
  FormControl,
  FormLabel,
  SimpleGrid,
  Box,
  Textarea,
  Avatar,
  Link,
} from '@chakra-ui/react';
import { Select as SelectWithSearch } from 'chakra-react-select';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import groupsApi from 'api/groups';
import { getTimezoneObject, getTimezonesInFormat } from 'utils/time';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';

export default function ModalEditGroup({ isOpen, onClose, onDeleteGroupOpen }) {
  const { currentGroup, fetchGroups } = useCurrentGroupsContext();
  const { t } = useMarkdownTranslations();
  const openDeleteModal = () => {
    onClose();
    onDeleteGroupOpen();
  };

  // Add useState hooks
  const [groupName, setGroupName] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [moreInfoUrl, setMoreInfoUrl] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [language, setLanguage] = useState('');
  const [timeZone, setTimeZone] = useState(getTimezoneObject('UTC'));
  const [timezonesOptions, setTimezonesOptions] = useState([]);

  useEffect(() => {
    if (!currentGroup) return;
    setGroupName(currentGroup.title);
    setIdentifier(currentGroup.slug);
    setMoreInfoUrl(currentGroup.url);
    setGroupDescription(currentGroup.description);
    setLanguage(currentGroup.language);
    if (currentGroup.timeZone) {
      setTimeZone(getTimezoneObject(currentGroup.timeZone));
    }
  }, [currentGroup]);

  const editGroup = async () => {
    let groupData = {};
    if (groupName) groupData = { ...groupData, title: groupName };
    if (identifier) groupData = { ...groupData, slug: identifier };
    if (moreInfoUrl) groupData = { ...groupData, url: moreInfoUrl };
    if (groupDescription) groupData = { ...groupData, info: groupDescription };
    if (language) groupData = { ...groupData, language: language };
    if (timeZone?.value)
      groupData = { ...groupData, time_zone: timeZone.value };

    try {
      await groupsApi.editGroup(currentGroup.id, { group: groupData });
      await fetchGroups();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setTimezonesOptions([...getTimezonesInFormat(), { value: '', label: '' }]);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius="16px"
        maxWidth={{ base: '90%', sm: '80%', md: '70%', lg: '60%' }}
        padding={4}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          borderBottom="1px"
          borderColor="gray.300"
          padding={4}
        >
          <Text fontSize="lg" fontWeight="bold" mb={0}>
            {t('group.edit')}
          </Text>
          <Text fontSize="sm" color="gray.600">
            {t('group.edit.description')}
          </Text>
        </Box>
        <ModalBody paddingY={4}>
          <Box p={4}>
            <Flex alignItems="center" gap={4}>
              <Box position="relative">
                <Avatar
                  bg="blue.600"
                  color="white"
                  name="Power Group"
                  size="md"
                  fontSize="md"
                />
                <Box
                  position="absolute"
                  bottom={-2}
                  right={-2}
                  bg="white"
                  borderRadius="full"
                  py={1}
                  px={2}
                  boxShadow="sm"
                >
                  <FontAwesomeIcon icon={faDownload} size="sm" />
                </Box>
              </Box>
              <Box>
                <Text fontSize="md" fontWeight="medium" mb={1}>
                  {t('group.power_name')}
                </Text>
                <Link
                  fontSize="sm"
                  color="gray.600"
                  textDecoration="underline"
                  _hover={{ color: 'blue.500' }}
                >
                  {t('group.change_logo')}
                </Link>
              </Box>
            </Flex>
          </Box>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mt={4}>
            <FormControl
              display="flex"
              justifyContent="space-between"
              flexDirection="column"
              gridColumn={{ base: 'span 1', md: 'span 1' }}
            >
              <FormLabel htmlFor="group-name" className="input-label-text">
                <Flex justifyContent="space-between" alignItems="center">
                  <span>{t('group.name')}</span>
                  <span>*</span>
                </Flex>
              </FormLabel>
              <Input
                id="group-name"
                className="input-modal-invite-participants"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
              />
            </FormControl>

            <FormControl gridColumn={{ base: 'span 1', md: 'span 2' }}>
              <FormLabel htmlFor="identifier" className="input-label-text">
                <Flex justifyContent="space-between">
                  <span>{t('group.identifier_info')}</span>
                  <span>*</span>
                </Flex>
              </FormLabel>
              <Input
                id="identifier"
                className="input-modal-invite-participants"
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
              />
            </FormControl>

            <FormControl
              gridColumn={{ base: 'span 1', md: 'span 3' }}
              position="relative"
            >
              <Box display="flex" justifyContent="space-between">
                <FormLabel
                  htmlFor="more-info-url"
                  className="input-label-texts"
                >
                  {t('group.url.more_info')}
                </FormLabel>
              </Box>
              <Input
                id="more-info-url"
                className="input-modal-invite-participants"
                value={moreInfoUrl}
                onChange={(e) => setMoreInfoUrl(e.target.value)}
              />
            </FormControl>
            <FormControl
              gridColumn={{ base: 'span 1', md: 'span 3' }}
              position="relative"
            >
              <Box display="flex" justifyContent="space-between">
                <FormLabel
                  htmlFor="group-description"
                  className="input-label-texts"
                >
                  {t('group.description')}
                </FormLabel>
              </Box>
              <Textarea
                id="group-description"
                className="input-modal-invite-participants"
                w="100%"
                value={groupDescription}
                onChange={(e) => setGroupDescription(e.target.value)}
              />
            </FormControl>
          </SimpleGrid>
          <Text fontSize="md" fontWeight="bold" mt={4}>
            {t('group.decisions.default')}
          </Text>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mt={4}>
            <FormControl gridColumn={{ base: 'span 1', md: 'span 1' }}>
              <FormLabel htmlFor="language" className="input-label-text">
                <Flex justifyContent="space-between" alignItems="center">
                  <span>{t('language')}</span>
                </Flex>
              </FormLabel>
              <SelectWithSearch
                id="language"
                className="select-participate-page"
                options={[
                  { value: 'en', label: t('language.english') },
                  { value: 'fr', label: t('language.french') },
                ]}
                value={
                  language
                    ? { value: language, label: language.toUpperCase() }
                    : null
                }
                onChange={(selected) =>
                  setLanguage(selected ? selected.value : '')
                }
                placeholder={t('language.select')}
              />
            </FormControl>
            <FormControl gridColumn={{ base: 'span 1', md: 'span 1' }}>
              <FormLabel htmlFor="time-zone" className="input-label-text">
                <Flex justifyContent="space-between" alignItems="center">
                  <span>{t('select.header.timezone')}</span>
                </Flex>
              </FormLabel>
              <SelectWithSearch
                id="time-zone"
                className="select-participate-page"
                options={timezonesOptions}
                value={timeZone}
                onChange={setTimeZone}
                placeholder={t('select.placeholder.timezone')}
              />
            </FormControl>
          </SimpleGrid>
        </ModalBody>
        <ModalFooter margin={0} pt={0} marginBottom={2}>
          <Box w="100%" display="flex" flexDirection="column" gap={6}>
            <Flex w="100%" justifyContent="space-between" gap={4}>
              <Button
                className="first-button-modal-invite"
                onClick={() => onClose()}
              >
                {t('btn.go_back')}
              </Button>
              <Button
                className="second-button-modal-invite"
                onClick={() => editGroup()}
              >
                {t('btn.confirm')}
              </Button>
            </Flex>
            <Flex w="100%" alignItems="center" gap={4}>
              <Button
                className="danger-button"
                onClick={() => openDeleteModal()}
              >
                {t('btn.group.delete')}
              </Button>
              <Box
                display="flex"
                alignItems="center"
                fontSize="sm"
                color="var(--DANGER-COLOR)"
              >
                {t('group.delete.warning')}
              </Box>
            </Flex>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
