import { useState, useEffect } from 'react';
import {
  Flex,
  Center,
  Button,
  Stack,
  Box,
  Grid,
  Image,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';
import './styles.css';
import politySideImage from '../../assets/images/polity_side.png';

const RecoverPasswordDone = () => {
  const { t } = useMarkdownTranslations();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Flex className="main-flex-recover-password-done" height="100vh">
      <Grid className="grid-recover-password-done">
        {windowWidth > 768 && (
          <Image
            src={politySideImage}
            className="side-image-recover-password-done"
            minHeight="100vh"
            maxHeight="100vh"
          />
        )}
        <Stack
          className="main-stack-recover-password-done"
          minHeight="100vh"
          maxHeight="100vh"
          justify="center"
        >
          <Box className="forgot-text-recover-password-done">
            {t('authentication.question.forgot_password')}
          </Box>
          <Box className="reset-text-recover-password-done">
            {t('authentication.password.reset_instructions')}
          </Box>
          <Box className="main-box-recover-password-done">
            <Center>
              <Button
                className="login-button-recover-password-done"
                as={RouterLink}
                to="/"
              >
                {t('btn.please_sign_in')}
              </Button>
            </Center>
          </Box>
        </Stack>
      </Grid>
    </Flex>
  );
};

export default RecoverPasswordDone;
