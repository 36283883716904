export const getErrorMessage = (errorCode) => {
  const errors = {
    auth: {
      invalid_email_or_password: 'errors.auth.invalid',
      login_not_found: 'errors.auth.not_found',
      reset_failed: 'errors.auth.reset_failed',
      unexpected_error: 'errors.auth.unexpected_error',
    },
    authentication: {
      web3: {
        invalid_address: 'errors.authentication.web3.invalid_address',
        invalid_nonce: 'errors.authentication.web3.invalid_nonce',
        invalid_signature: 'errors.authentication.web3.invalid_signature',
        invite_not_found: 'errors.invite_not_found',
        invite_used: 'errors.invite_used',
        no_metamask: 'errors.authentication.web3.no_metamask',
        not_found: 'errors.authentication.web3.not_found',
        rejected: 'errors.authentication.web3.rejected',
        taken: 'errors.authentication.web3.taken',
      },
    },
    code: {
      invite_not_found: 'errors.invite_not_found',
      invite_used: 'errors.invite_used',
    },
    invite: {
      invite_not_found: 'errors.invite_not_found',
      invite_used: 'errors.invite_used',
    },
    messages: {
      already_invited: 'errors.messages.already_invited',
      already_in_group: 'errors.messages.already_in_group',
      invalid_invite_prospect: 'errors.messages.invalid_invite_prospect',
      accepted: 'errors.accepted',
      blank: 'errors.blank',
      inclusion: 'errors.messages.inclusion',
      invalid: 'errors.messages.invalid',
      no_html: 'errors.messages.no_html',
      not_a_number: 'errors.messages.not_a_number',
      not_an_integer: 'errors.messages.not_an_integer',
      taken: 'errors.messages.taken',
      confirmation: {
        match: 'errors.messages.confirmation.match',
        presence: 'errors.blank',
      },
      email: {
        invalid: 'errors.messages.email.invalid',
        presence: 'errors.blank',
      },
      empty: 'errors.blank',
      exclusion: 'is reserved',
      file: {
        file_type: 'errors.file.format',
        image_format: 'errors.file.format',
        size: 'errors.file.size',
      },
      password: {
        format: 'errors.messages.password.format',
        length_high: 'errors.messages.password.length_high',
        length_low: 'errors.messages.password.length_low',
        presence: 'errors.blank',
      },
      terms: {
        accepted: 'errors.accepted',
      },
      too_long: {
        one: 'errors.messages.too_long.one',
        other: 'errors.messages.too_long.other',
      },
      too_short: {
        one: 'errors.messages.too_short.one',
        other: 'errors.messages.too_short.other',
      },
      username: {
        length: 'errors.messages.username.length',
        presence: 'errors.blank',
      },
      wrong_length: {
        one: 'errors.messages.wrong_length.one',
        other: 'errors.messages.wrong_length.other',
      },
    },
  };

  const partsOfCode = errorCode.split('.').slice(1);
  let message = errors;

  try {
    for (const part of partsOfCode) {
      message = message[part];
    }

    if (message) return message;
  } catch (error) {
    return 'error';
  }
};
