import React, { useState, useEffect } from 'react';
import { Flex, Button, Spinner } from '@chakra-ui/react';
import LeftArrow from 'images/leftArrow';
import RightArrow from 'images/rightArrow';
import useMarkdownTranslations from '../translations/i18nextConfig/useMarkdownTranslations';
import { groupsPerPage } from 'api/groups';

export default function Pagination({
  search,
  sortBy,
  groups,
  totalGroups,
  setPaginatedGroups,
  fetchData,
}) {
  const { t } = useMarkdownTranslations();
  const [pageIndex, setPageIndex] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const [currentBatch, setCurrentBatch] = useState([]);
  const [batchIndex, setBatchIndex] = useState(1);
  const [allGroups, setAllGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  const paginate = (groups, page) => {
    const startIndex = (page - 1) * groupsPerPage;
    const endIndex = startIndex + groupsPerPage;
    return groups.slice(startIndex, endIndex);
  };

  useEffect(() => {
    const commonGroups = groups.filter((group) =>
      currentBatch.some((e) => e.id === group.id),
    );
    if (currentBatch.length > 0 && commonGroups.length === currentBatch.length)
      return;
    const newGroups = [...currentBatch, ...groups];
    setCurrentBatch(newGroups);
    setPaginatedGroups(paginate(newGroups, pageIndex));
    setTotalPages(Math.ceil(totalGroups / groupsPerPage));
    if (newGroups.length === totalGroups && search === '') {
      setAllGroups(newGroups);
    }
  }, [groups]);

  useEffect(() => {
    if (allGroups.length < totalGroups) {
      setCurrentBatch([]);
      loadGroups(1);
    } else {
      const filteredGroups = allGroups
        .filter((group) =>
          group.title.toLowerCase().includes(search.toLowerCase()),
        )
        .sort((a, b) => {
          if (sortBy === 'group_title') {
            return a.title.localeCompare(b.title);
          }
          return 0;
        });
      setCurrentBatch(filteredGroups);
      setPaginatedGroups(paginate(filteredGroups, 1));
      setTotalPages(Math.ceil(filteredGroups.length / groupsPerPage));
    }
    setPageIndex(1);
    setBatchIndex(1);
  }, [search, sortBy]);

  const previousPage = async (nextPageIndex) => {
    if (nextPageIndex < 1 || loading) return;
    setPageIndex(nextPageIndex);
    setPaginatedGroups(paginate(currentBatch, nextPageIndex));
  };

  const nextPage = (nextPageIndex) => {
    if (nextPageIndex > totalPages || loading) return;
    const endIndex = nextPageIndex * groupsPerPage;
    setPageIndex(nextPageIndex);

    if (endIndex > currentBatch.length && currentBatch.length < totalGroups) {
      loadGroups(batchIndex + 1);
      setBatchIndex(batchIndex + 1);
    } else {
      setPaginatedGroups(paginate(currentBatch, nextPageIndex));
    }
  };

  const pageClick = (page) => {
    if (page > pageIndex) {
      nextPage(page);
    } else {
      previousPage(page);
    }
  };

  const getPaginationButtons = () => {
    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <Button
          key={i}
          color={pageIndex === i ? 'var(--MAIN-TEXT)' : 'var(--MAIN-GREY)'}
          bgColor={
            pageIndex === i ? 'var(--MAIN-GREY)' : 'var(--CLEAR-BACKGROUND)'
          }
          _hover={{
            bg:
              pageIndex === i ? 'var(--MAIN-GREY)' : 'var(--CLEAR-BACKGROUND)',
          }}
          onClick={() => pageClick(i)}
          cursor="pointer"
          isDisabled={loading}
          mx={1}
        >
          {i}
        </Button>,
      );
    }
    return buttons;
  };

  useEffect(() => {
    loadGroups(1);
  }, []);

  const loadGroups = async (page) => {
    setLoading(true);
    const sort = sortBy === 'none' ? null : sortBy;
    await fetchData(page, sort, search);
    setLoading(false);
  };

  return (
    <>
      {loading && (
        <Flex
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="rgba(255, 255, 255, 0.7)"
          justify="center"
          align="center"
          zIndex={10}
        >
          <Spinner size="lg" />
        </Flex>
      )}
      <Flex
        className="outer-flex-hosted-decisions-page"
        role="region"
        aria-label={t('accessibility.pagination.arrows')}
        mt={6}
        justify="center"
        align="center"
        gap={2}
      >
        <Button
          className="button-arrow-page-template-list"
          onClick={() => previousPage(pageIndex - 1)}
          isDisabled={pageIndex <= 1 || loading}
          cursor="pointer"
        >
          <LeftArrow active={pageIndex > 1} />
        </Button>
        <Flex
          className="flex-pagination-buttons-hosted-decisions-page"
          align="center"
        >
          {getPaginationButtons()}
        </Flex>
        <Button
          className="button-arrow-page-template-list"
          onClick={() => nextPage(pageIndex + 1)}
          isDisabled={pageIndex >= totalPages || loading}
          cursor="pointer"
        >
          <RightArrow active={pageIndex < totalPages} />
        </Button>
      </Flex>
    </>
  );
}
