import React from 'react';
import useCurrentUser from 'features/user/hooks/useCurrentUser';

const UserContext = React.createContext();

const UserProvider = ({ children }) => {
  const currentUserState = useCurrentUser();

  return (
    <UserContext.Provider value={currentUserState}>
      {children}
    </UserContext.Provider>
  );
};

const useCurrentUserContext = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('currentUserState must be used within a UserProvider');
  }
  return context;
};

export { UserProvider, useCurrentUserContext };
