import React, { useState, useEffect } from 'react';
import {
  Card,
  Flex,
  Stack,
  CardBody,
  Text,
  Avatar,
  Button,
  Link,
  Box,
} from '@chakra-ui/react';
import { useCurrentUserContext } from 'features/user/contexts/user-provider';
import useMarkdownTranslations from '../translations/i18nextConfig/useMarkdownTranslations';

export default function WelcomeBanner({ isFirstLogin }) {
  const { user } = useCurrentUserContext();
  const { t, tMarkdown } = useMarkdownTranslations();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Card className="card-header-home-dashboard" variant="outline">
      <Flex
        className="flex-center-header-home-dashboard"
        justifyContent={isFirstLogin ? 'space-between' : 'flex-start'}
        alignItems="center"
      >
        {!isFirstLogin && (
          <Avatar src={user?.avatar} className="avatar-header-home-dashboard" />
        )}
        <Stack
          className="stack-header-home-dashboard"
          role="main"
          aria-label={t('accessibility.welcome')}
        >
          <CardBody className="card-body-header-home-dashboard">
            <Text className="main-text-header-home-dashboard">
              {isFirstLogin
                ? t('account.welcome.first_login')
                : t('account.welcome.back')}
              {windowWidth > 768 && ','}{' '}
              <span className="name-text-header-home-dashboard">
                {user?.name}
              </span>
            </Text>
            {windowWidth > 768 && (
              <Box className="secondary-text-header-home-dashboard">
                {isFirstLogin
                  ? tMarkdown('account.welcome.first_login.description_html')
                  : tMarkdown('account.welcome.description_html')}
              </Box>
            )}
          </CardBody>
        </Stack>
        {isFirstLogin && (
          <Link
            className="link-to-edit-decision-create-decision-modal"
            href="/"
          >
            <Button className="main-button">
              {t('account.welcome.learn_more')}
            </Button>
          </Link>
        )}
      </Flex>
    </Card>
  );
}
