import React, { useState, useEffect } from 'react';
import WalletHeader from '../../components/WalletHeader';
import WalletBody from '../../components/WalletBody';
import { Box, Grid } from '@chakra-ui/react';
import CreateDecisionHeader from '../../components/CreateDecisionHeader';
import './styles.css';

export default function Wallet() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid className="main-grid-wallet" templateColumns="100%" gap={3}>
      {windowWidth < 768 && (
        <Box className="box-header-wallet">
          <CreateDecisionHeader />
        </Box>
      )}
      <WalletHeader />
      <WalletBody />
    </Grid>
  );
}
