class LocalStorage {
  static sharedInstance = LocalStorage.getInstance();

  static createInstance() {
    const object = new LocalStorage();
    return object;
  }

  static getInstance() {
    if (!LocalStorage.instance) {
      LocalStorage.sharedInstance = LocalStorage.createInstance();
    }
    return LocalStorage.sharedInstance;
  }

  static set(key, data) {
    const value = typeof data === 'object' ? JSON.stringify(data) : data;
    localStorage.setItem(key, value);
  }

  static update(key, data) {
    const value = localStorage.getItem(key)
      ? JSON.parse(localStorage.getItem(key))
      : {};
    localStorage.removeItem(key);
    localStorage.setItem(
      key,
      JSON.stringify({
        ...value,
        ...data,
      }),
    );
  }

  static get(key) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (error) {
      return localStorage.getItem(key);
    }
  }

  static clear() {
    localStorage.clear();
    //This function is being called when user logout.
    // Make sure after logout user redirect to proper route
  }
}

const object = {
  set: LocalStorage.set,
  get: LocalStorage.get,
  clear: LocalStorage.clear,
};

export default object;
