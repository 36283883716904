import React, { useEffect, useState } from 'react';
import { Box, Text, Link, Flex, Button, Image, Avatar } from '@chakra-ui/react';
import ModalInviteGroup from '../../pages/GroupPanelHost/Sections/ModalInviteGroup';
import ModalLeaveGroup from 'components/ModalLeaveGroup';
import logout from 'assets/images/logout.png';
import userAdd from 'assets/images/user_add.png';
import viewList from 'assets/images/view_list.png';
import { useCurrentUserContext } from 'features/user/contexts/user-provider';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import { Separator } from 'components/Separator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import AcceptInvitationModal from 'components/AcceptInvitationModal';
import DeclineInvitationModal from 'components/DeclineInvitationModal';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

export default function Card({ group, color, isInvited }) {
  const { fetchDecisions } = useCurrentGroupsContext();
  const { user } = useCurrentUserContext();
  const { t } = useMarkdownTranslations();
  const navigate = useNavigate();

  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  const [role, setRole] = useState('');

  const handleInviteModal = async () => {
    if (!group.id) return;
    await fetchDecisions(group.id);
    setIsInviteModalOpen(true);
  };

  useEffect(() => {
    let translationString = 'group.user.roles.';
    if (group.isOwner) {
      translationString += 'owner';
      setRole(translationString);
    } else if (group.roles[0]) {
      translationString += group.roles[0];
      setRole(translationString);
    }
  }, [user, group]);

  if (!group) return null;

  return (
    <Flex
      direction="column"
      py={6}
      borderWidth="1px"
      height="100%"
      borderRadius="18px"
    >
      <Flex
        flex="1"
        mx={6}
        textAlign="left"
        justifyContent="flex-start"
        width="100%"
      >
        <Avatar
          name={group.title.charAt(0)?.toUpperCase()}
          bg={color}
          size="lg"
        />
        <Box marginLeft={5}>
          <Text className="group-card-header">{group.title}</Text>
          {group.url && (
            <Link
              className="group-card-link"
              display="flex"
              gap={3}
              alignItems="center"
              target="_blank"
              href={group.url}
            >
              {group.url}
              <FontAwesomeIcon icon={faExternalLink} color="var(--MAIN-GREY)" />
            </Link>
          )}
          <Text className="group-card-subheader">
            {t('btn.members_amount', {
              amount: group.members,
            })}
          </Text>
          {group.decisions > 0 && (
            <Text className="group-card-subheader">
              {t('btn.decisions.active_amount', {
                amount: group.decisions,
              })}
            </Text>
          )}
        </Box>
      </Flex>
      {role && !role.includes('member') && (
        <Box mx={6} className="info-button" borderRadius="4px">
          {t(role)}
        </Box>
      )}
      <Separator />
      <Box px={6}>
        <Flex justify="space-between" align="center" gap={2}>
          {!isInvited && !role.includes('member') && (
            <>
              <Button
                flex="1"
                py={3}
                px={7}
                height="100%"
                className="secondary-button"
                onClick={handleInviteModal}
              >
                <Image src={userAdd} />
              </Button>
              <Button
                flex="1"
                _hover={{ textDecoration: 'none' }}
                textDecoration={'none'}
                py={3}
                px={7}
                height="100%"
                onClick={() => {
                  navigate(`/g/${group.slug}/members`);
                }}
                className="secondary-button"
              >
                <Image src={viewList} />
              </Button>
            </>
          )}
          {role.includes('member') && (
            <Button
              flex="1"
              className="main-button"
              height="100%"
              width="100%"
              py={4}
              px={7}
              _hover={{ textDecoration: 'none' }}
              textDecoration={'none'}
              onClick={() => {
                navigate(`/g/${group.slug}/decisions`);
              }}
              isDisabled={group.decisions === 0}
            >
              {t('btn.decisions')}
            </Button>
          )}
          {!group.isOwner && !isInvited && (
            <Button
              flex="1"
              py={3}
              px={7}
              height="100%"
              onClick={() => setIsLeaveModalOpen(true)}
              className="secondary-button"
            >
              <Image src={logout} />
            </Button>
          )}
        </Flex>
        {!isInvited && !role.includes('member') && (
          <Flex>
            <Button
              flex="1"
              className="main-button"
              width="100%"
              height="100%"
              mt="1rem"
              py={4}
              px={7}
              _hover={{ textDecoration: 'none' }}
              textDecoration={'none'}
              onClick={() => {
                navigate(`/g/${group.slug}/decisions`);
              }}
              isDisabled={group.decisions === 0}
            >
              {t('btn.decisions')}
            </Button>
          </Flex>
        )}
        {isInvited && (
          <Flex align="center" gap={2}>
            <Button
              flex="1"
              height="100%"
              py={4}
              px={7}
              className="main-button"
              onClick={() => setIsAcceptModalOpen(true)}
            >
              {t('btn.accept_invitation')}
            </Button>
            <Button
              flex="1"
              height="100%"
              py={4}
              px={7}
              className="secondary-button"
              onClick={() => setIsDeclineModalOpen(true)}
            >
              {t('btn.decline_invitation')}
            </Button>
          </Flex>
        )}
        <Flex align="center" gap={2}>
          {!isInvited && !role.includes('member') && (
            <Button
              flex="1"
              height="100%"
              py={4}
              px={7}
              mt="1rem"
              className="main-button"
              onClick={() => {
                navigate('/create-decisions');
              }}
              _hover={{ textDecoration: 'none' }}
            >
              {t('decision.create')}
            </Button>
          )}
          <Button
            flex="1"
            height="100%"
            mt="1rem"
            py={4}
            px={7}
            className="main-button"
            _hover={{ textDecoration: 'none' }}
            textDecoration={'none'}
            onClick={() => {
              navigate(`/g/${group.slug}`);
            }}
            display="flex"
            justifyContent="space-between"
          >
            {t('btn.group.view_link')}
            <FontAwesomeIcon icon={faAngleRight} cursor="pointer" />
          </Button>
        </Flex>
      </Box>
      <ModalInviteGroup
        isOpen={isInviteModalOpen}
        onClose={() => setIsInviteModalOpen(false)}
        groupId={group.id}
      />
      <ModalLeaveGroup
        isOpen={isLeaveModalOpen}
        onClose={() => setIsLeaveModalOpen(false)}
        groupId={group.id}
      />
      <AcceptInvitationModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        group={group}
      />
      <DeclineInvitationModal
        isOpen={isDeclineModalOpen}
        onClose={() => setIsDeclineModalOpen(false)}
        group={group}
      />
    </Flex>
  );
}
