export const paymentsInitState = {
  sharesAmount: 0,
  subscription: false,
  membershipDate: null,
  totalInvestment: 0,
  id: null,
};

/* eslint-disable no-fallthrough */
export const paymentsReducer = (state = paymentsInitState, action) => {
  switch (action.type) {
    case 'SET_SHARES_AMOUNT':
      return {
        ...state,
        sharesAmount: action.payload,
      };
    case 'SET_SUBSCRIPTION':
      return {
        ...state,
        subscription: action.payload,
      };
    case 'SET_MEMBERSHIP_DATE':
      return {
        ...state,
        membershipDate: action.payload,
      };
    case 'SET_TOTAL_INVESTMENT':
      return {
        ...state,
        totalInvestment: action.payload,
      };
    case 'SET_ID':
      return {
        ...state,
        id: action.payload,
      };
    default:
      return state;
  }
};
/* eslint-enable */
