import React, { useState } from 'react';
import {
  Card,
  Box,
  Button,
  Text,
  Flex,
  Link,
  useDisclosure,
  Image,
  Tooltip,
} from '@chakra-ui/react';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';
import { timeDifference, formatDate } from 'utils/time';
import ModalInviteParticipants from 'components/ModalInviteParticipants';
import standardImage from 'assets/images/standard_image.png';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const DecisionCardHost = ({ currentDecision }) => {
  const { t } = useMarkdownTranslations();
  const [isError, setIsError] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  return (
    <>
      <ModalInviteParticipants
        isOpen={isOpen}
        onClose={onClose}
        currentDecision={currentDecision}
      />
      <Card className="card-decision-card-host">
        <Box className="box-image-decision-card-host">
          <Image
            className="image-decision-card-host"
            src={
              isError ? standardImage : currentDecision.image || standardImage
            }
            alt={t('decision')}
            onError={() => setIsError(true)}
          />
        </Box>
        <Text className="title-decision-card-host">
          {currentDecision.title}
        </Text>
        <Link
          href={
            currentDecision.currentMode === 'Unpublished'
              ? currentDecision?.links?.preview
              : currentDecision?.links?.visit
          }
          target="_blank"
        >
          {currentDecision.currentMode === 'Unpublished'
            ? currentDecision?.links?.preview
            : currentDecision?.links?.visit}
        </Link>
        {currentDecision.invited && (
          <Link href={currentDecision?.links?.visit}>
            <Button className="special-link-button-decision-card-host">
              <FontAwesomeIcon
                icon="fa-regular fa-envelope"
                className="icon-button-decision-card-host"
              />
              {t('btn.accept_invitation')}
            </Button>
          </Link>
        )}

        {!currentDecision.invited && currentDecision.currentMode === 'Open' && (
          <Link href={currentDecision?.links?.visit}>
            <Button className="special-link-button-decision-card-host">
              <FontAwesomeIcon
                icon="fa-regular fa-moon"
                className="icon-button-decision-card-host"
              />
              {t('btn.completed_percentage', {
                percentage: currentDecision.completed
                  ? currentDecision.completed
                  : 0,
              })}
            </Button>
          </Link>
        )}

        {!currentDecision.invited &&
          currentDecision.currentMode.includes('Finished') && (
            <Link href={currentDecision?.links?.results}>
              <Button className="special-link-button-decision-card-host">
                <FontAwesomeIcon
                  icon={faCheck}
                  className="icon-button-decision-card-host"
                />
                {t('btn.view_results')}
              </Button>
            </Link>
          )}

        {!currentDecision.invited &&
          currentDecision.currentMode.includes('Unpublished') && (
            <Link href={currentDecision?.links?.results}>
              <Button className="special-link-button-decision-card-host">
                <FontAwesomeIcon
                  icon="fa-regular fa-moon"
                  className="icon-button-decision-card-host"
                />
                {t('btn.unpublished')}
              </Button>
            </Link>
          )}
        <Flex direction="column">
          <Flex className="date-flex-decision-card-host">
            <Text className="date-tag-decision-card-host">
              {t('decision.details.opens_on')}:
            </Text>
            <Text ml={0} className="date-text-decision-card-host">
              {currentDecision.openAt ? (
                <>
                  {formatDate(currentDecision.openAt)} (
                  {timeDifference(currentDecision.openAt)} )
                </>
              ) : (
                <>{t('decision.details.to_be_determined')}</>
              )}
            </Text>
          </Flex>
          <Flex className="date-flex-decision-card-host">
            <Text className="date-tag-decision-card-host">
              {t('decision.details.closes')}:
            </Text>
            <Text ml={0} className="date-text-decision-card-host">
              {currentDecision.closeAt ? (
                <>
                  {formatDate(currentDecision.closeAt)} (
                  {timeDifference(currentDecision.closeAt)} )
                </>
              ) : (
                <>{t('decision.details.to_be_determined')}</>
              )}
            </Text>
          </Flex>
        </Flex>

        <Flex
          className="flex-buttons-decision-card-host"
          gap={2}
          direction="row"
        >
          {currentDecision?.links?.visit && (
            <Tooltip label={t('tooltip.visit')}>
              <Link
                href={currentDecision?.links?.visit}
                target="_blank"
                width="100%"
              >
                <Button className="icon-bottom-button-decision-card-host">
                  <FontAwesomeIcon
                    icon="fa-solid fa-eye"
                    className="icon-decision-card-host"
                  />
                </Button>
              </Link>
            </Tooltip>
          )}
          {currentDecision?.links?.edit && (
            <Tooltip label={t('tooltip.edit')}>
              <Link
                href={currentDecision?.links?.edit}
                target="_blank"
                width="100%"
              >
                <Button className="icon-bottom-button-decision-card-host">
                  <FontAwesomeIcon
                    icon="fa-solid fa-pen"
                    className="icon-decision-card-host"
                  />
                </Button>
              </Link>
            </Tooltip>
          )}
          {currentDecision?.links?.analysis && (
            <Tooltip label={t('tooltip.analysis')}>
              <Link
                href={currentDecision?.links?.analysis}
                target="_blank"
                width="100%"
              >
                <Button className="icon-bottom-button-decision-card-host">
                  <FontAwesomeIcon
                    icon="fa-solid fa-chart-simple"
                    className="icon-decision-card-host"
                  />
                </Button>
              </Link>
            </Tooltip>
          )}

          <Tooltip label={t('tooltip.invite')}>
            <Link width="100%" onClick={() => onOpen()}>
              <Button className="icon-bottom-button-decision-card-host">
                <FontAwesomeIcon
                  icon="fa-solid fa-user-plus"
                  className="icon-decision-card-host"
                />
              </Button>
            </Link>
          </Tooltip>

          {currentDecision?.links?.comments && (
            <Tooltip label={t('tooltip.comments')}>
              <Link
                href={currentDecision?.links?.comments}
                target="_blank"
                width="100%"
              >
                <Button className="icon-bottom-button-decision-card-host">
                  <FontAwesomeIcon
                    icon="fa-solid fa-comment"
                    className="icon-decision-card-host"
                  />
                </Button>
              </Link>
            </Tooltip>
          )}
        </Flex>

        <Button
          className="button-details-decision-card-host"
          rightIcon={<FontAwesomeIcon icon="fa-solid fa-chevron-down" />}
          justifyContent="space-between"
          onClick={() =>
            navigate(`/current-decision/${currentDecision.group.decisionId}`)
          }
        >
          {t('btn.show_details')}
        </Button>
      </Card>
    </>
  );
};

export default DecisionCardHost;
