import React, { useState } from 'react';
import RecoverPasswordForm from 'components/RecoverPasswordForm';
import RecoverPasswordDone from 'components/RecoverPasswordDone';
import { Box } from '@chakra-ui/react';

export default function RecoverPassword() {
  const [wasMailSent, setWasMailSent] = useState(false);
  return (
    <Box id="main">
      {wasMailSent ? (
        <RecoverPasswordDone />
      ) : (
        <RecoverPasswordForm setWasMailSent={setWasMailSent} />
      )}
    </Box>
  );
}
