import { Box, Text } from '@chakra-ui/react';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';

const GroupDescription = ({ currentGroup }) => {
  const { t } = useMarkdownTranslations();
  return (
    <Box
      w="100%"
      h="fit-content"
      pt="15px"
      gap="10px"
      borderWidth="1px 0px 0px 0px"
      boxShadow="0px 0px 7px 2px rgba(0, 0, 0, 0.03)"
      background="var(--CLEAR-BACKGROUND)"
      borderRadius="18px"
      p="16px"
    >
      <Text fontSize="18px" fontWeight="700" color="var(--MAIN-GREY)">
        {t('btn.info')}
      </Text>
      {currentGroup.description}
    </Box>
  );
};

export default GroupDescription;
