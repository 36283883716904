import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Image,
  Text,
  Link,
  Flex,
  Box,
  Tooltip,
} from '@chakra-ui/react';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HTMLReactParser from 'html-react-parser';
import { timeDifference, formatDate } from 'utils/time';
import './styles.css';
import standardImage from '../../assets/images/standard_image.png';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export default function ModalDecisionCardHost({
  isOpen,
  onClose,
  currentDecision,
}) {
  const { t } = useMarkdownTranslations();
  const [isError, setIsError] = useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="16px">
        <ModalBody>
          <Box className="main-box-modal-decision-card-host">
            <Image
              className="image-modal-decision-card-host"
              src={
                isError ? standardImage : currentDecision.image || standardImage
              }
              alt={t('decision')}
              onError={() => setIsError(true)}
            />
            <Text className="title-modal-decision-card-host">
              {currentDecision.title}
            </Text>
            <Link
              className="main-link-modal-decision-card-host"
              href={
                currentDecision.currentMode === 'Unpublished'
                  ? currentDecision?.links?.preview
                  : currentDecision?.links?.visit
              }
              target="_blank"
            >
              {currentDecision.currentMode === 'Unpublished'
                ? currentDecision?.links?.preview
                : currentDecision?.links?.visit}
            </Link>
            {currentDecision.invited && (
              <Link href={currentDecision?.links?.visit}>
                <Button className="special-link-button-modal-decision-card-host">
                  <FontAwesomeIcon
                    icon="fa-regular fa-envelope"
                    className="icon-button-modal-decision-card-host"
                  />
                  {t('btn.accept_invitation')}
                </Button>
              </Link>
            )}
            {!currentDecision.invited &&
              currentDecision.currentMode === 'Open' && (
                <Link href={currentDecision?.links?.visit}>
                  <Button className="special-link-button-modal-decision-card-host">
                    <FontAwesomeIcon
                      icon="fa-regular fa-moon"
                      className="icon-button-modal-decision-card-host"
                    />
                    {t('btn.completed_percentage', {
                      percentage: currentDecision.completed
                        ? currentDecision.completed
                        : 0,
                    })}
                  </Button>
                </Link>
              )}

            {!currentDecision.invited &&
              currentDecision.currentMode?.includes('Finished') && (
                <Link href={currentDecision?.links?.results}>
                  <Button className="special-link-button-modal-decision-card-host">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="icon-button-modal-decision-card-host"
                    />
                    {t('btn.view_results')}
                  </Button>
                </Link>
              )}
          </Box>
          <Flex direction="column">
            <Flex className="date-flex-modal-decision-card-host">
              <Text className="date-tag-modal-decision-card-host">
                {t('decision.details.opened')}
              </Text>
              <Text ml={0} className="date-text-modal-decision-card-host">
                {currentDecision.openAt ? (
                  <>
                    {formatDate(currentDecision.openAt)} (
                    {timeDifference(currentDecision.openAt)} )
                  </>
                ) : (
                  <>{t('decision.details.to_be_determined')}</>
                )}
              </Text>
            </Flex>
            <Flex className="date-flex-modal-decision-card-host">
              <Text className="date-tag-modal-decision-card-host">
                {t('decision.details.closes')}
              </Text>
              <Text ml={0} className="date-text-modal-decision-card-host">
                {currentDecision.closeAt ? (
                  <>
                    {formatDate(currentDecision.closeAt)} (
                    {timeDifference(currentDecision.closeAt)} )
                  </>
                ) : (
                  <>{t('decision.details.to_be_determined')}</>
                )}
              </Text>
            </Flex>

            {currentDecision.description && (
              <Flex className="about-flex-modal-decision-card-host">
                <Text className="about-text-modal-decision-card-host">
                  {t('decision.details.about')}
                </Text>
                {HTMLReactParser(currentDecision.description)}
              </Flex>
            )}
          </Flex>
          <Flex
            className="flex-buttons-modal-modal-decision-card-host"
            gap={2}
            direction="row"
          >
            {currentDecision?.links?.visit && (
              <Tooltip label={t('tooltip.visit')}>
                <Link
                  href={currentDecision?.links?.visit}
                  target="_blank"
                  width="100%"
                >
                  <Button className="icon-bottom-button-modal-decision-card-host">
                    <FontAwesomeIcon
                      icon="fa-solid fa-eye"
                      className="icon-decision-card-host"
                    />
                  </Button>
                </Link>
              </Tooltip>
            )}
            {currentDecision?.links?.edit && (
              <Tooltip label={t('tooltip.edit')}>
                <Link
                  href={currentDecision?.links?.edit}
                  target="_blank"
                  width="100%"
                >
                  <Button className="icon-bottom-button-decision-card-host">
                    <FontAwesomeIcon
                      icon="fa-solid fa-pen"
                      className="icon-decision-card-host"
                    />
                  </Button>
                </Link>
              </Tooltip>
            )}
            {currentDecision?.links?.analysis && (
              <Tooltip label={t('tooltip.analysis')}>
                <Link
                  href={currentDecision?.links?.analysis}
                  target="_blank"
                  width="100%"
                >
                  <Button className="icon-bottom-button-decision-card-host">
                    <FontAwesomeIcon
                      icon="fa-solid fa-chart-simple"
                      className="icon-decision-card-host"
                    />
                  </Button>
                </Link>
              </Tooltip>
            )}
            {currentDecision?.links?.comments && (
              <Tooltip label={t('tooltip.comments')}>
                <Link
                  href={currentDecision?.links?.comments}
                  target="_blank"
                  width="100%"
                >
                  <Button className="icon-bottom-button-decision-card-host">
                    <FontAwesomeIcon
                      icon="fa-solid fa-comment"
                      className="icon-decision-card-host"
                    />
                  </Button>
                </Link>
              </Tooltip>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => onClose()}
            className="button-details-modal-decision-card-host"
            rightIcon={<FontAwesomeIcon icon="fa-solid fa-chevron-up" />}
            justifyContent="space-between"
          >
            {t('btn.hide_details')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
