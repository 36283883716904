import React, { useState, useEffect } from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Flex,
  Text,
  Button,
  useDisclosure,
  InputGroup,
  InputRightElement,
  Image,
  Input,
  Select,
  Circle,
  Link,
} from '@chakra-ui/react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ModalEditGroup from 'components/ModalEditGroup';
import ModalInviteGroup from './Sections/ModalInviteGroup';
import ModalCreateGroupDecision from 'components/ModalCreateGroupDecision';
import TableOfGroupInvites from 'pages/TableOfGroupInvites';
import TableOfGroupMembers from 'pages/TableOfGroupMembers';
import GroupsDecisions from 'components/GroupsDecisions';
import GroupDescription from 'pages/GroupDescription';
import ModalDeleteGroup from 'components/ModalDeleteGroup';
import searchIcon from '../../assets/images/search.png';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import WelcomeBanner from 'components/WelcomeBanner';
import LoadingScreen from 'components/LoadingScreen';
import notify from 'common/utils/notify';

export default function GroupPanelHost() {
  const location = useLocation();
  const navigate = useNavigate();
  const { slug } = useParams();
  const { t } = useMarkdownTranslations();

  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sortBy, setSortBy] = useState('none');
  const [status, setStatus] = useState('All');
  const [search, setSearch] = useState('');
  const [currentTab, setCurrentTab] = useState(0); // State to track the current tab
  const [isAdmin, setIsAdmin] = useState(true);
  const {
    groupsHosted,
    groupsData,
    setDataOfCurrentGroup,
    currentGroup,
    fetchDecisions,
    fetchInvitesOfGroup,
    fetchUsersOfGroup,
  } = useCurrentGroupsContext();

  useEffect(() => {
    const isNewGroup = localStorage.getItem('newGroup') === 'true';
    if (isNewGroup && !isLoading) {
      notify('success', t('group.created.successfully'));
      localStorage.removeItem('newGroup');
    }
  }, [isLoading]);

  const listOfStatus = [
    { label: t('decision.status.all'), value: 'All' },
    { label: t('decision.status.open'), value: 'Open' },
    { label: t('decision.status.unpublished'), value: 'Unpublished' },
    { label: t('decision.status.coming_soon'), value: 'Coming Soon' },
    { label: t('decision.status.finished'), value: 'Finished' },
    { label: t('decision.status.shut_down'), value: 'Shut Down' },
  ];

  const listOfSortsTableOfGroupMembers = [
    { label: 'Sort by', value: 'none' },
    { label: 'Name', value: 'name' },
    { label: 'Email', value: 'email' },
    { label: 'Web3 Address', value: 'web3Address' },
    { label: 'Role', value: 'role' },
    { label: 'Last Invite Sent', value: 'lastInviteSent' },
  ];

  const listOfSortsDecisions = [
    { label: 'Sort by', value: 'none' },
    { label: 'Title', value: 'title' },
    { label: 'Url', value: 'url' },
  ];

  const returnListOfSortsData = (currentTab) => {
    if (currentTab === 1) {
      return listOfSortsDecisions;
    } else if (currentTab === 3 || currentTab === 2) {
      return listOfSortsTableOfGroupMembers;
    }
  };

  const {
    isOpen: isEditGroupOpen,
    onOpen: onEditGroupOpen,
    onClose: onEditGroupClose,
  } = useDisclosure();

  // useDisclosure for Create Decision modal
  const {
    isOpen: isCreateDecisionOpen,
    onOpen: onCreateDecisionOpen,
    onClose: onCreateDecisionClose,
  } = useDisclosure();

  // useDisclosure for Invite Group Member modal
  const {
    isOpen: isInviteGroupOpen,
    onOpen: onInviteGroupOpen,
    onClose: onInviteGroupClose,
  } = useDisclosure();

  // useDisclosure for Invite Group Member modal
  const {
    isOpen: isDeleteGroupOpen,
    onOpen: onDeleteGroupOpen,
    onClose: onDeleteGroupClose,
  } = useDisclosure();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const currentGroupElement = groupsData.find(
          (group) => group.slug === slug,
        );
        if (!currentGroupElement) return;
        await fetchDecisions(currentGroupElement.id);
        if (currentGroupElement.isOwner) {
          await fetchInvitesOfGroup(currentGroupElement.id);
          await fetchUsersOfGroup(currentGroupElement.id);
        }
        setDataOfCurrentGroup(currentGroupElement);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [groupsData, slug]);

  useEffect(() => {
    try {
      const slugList = groupsHosted.groups.map((group) => group.slug);
      if (slugList.includes(slug)) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    } catch (error) {
      setIsAdmin(false);
    }
  }, [groupsHosted?.groups, slug]);

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];

    switch (lastSegment) {
      case 'description':
        setCurrentTab(0);
        break;
      case 'decisions':
        setCurrentTab(1);
        break;
      case 'members':
        setCurrentTab(2);
        break;
      case 'invites':
        setCurrentTab(3);
        break;
      default:
        setCurrentTab(0);
    }
  }, [location.pathname]);

  const handleOpenModal = () => {
    switch (currentTab) {
      case 0:
        onEditGroupOpen();
        break;
      case 1:
        navigate('/create-decisions');
        break;
      case 2:
      case 3:
        onInviteGroupOpen();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const firstLogin = sessionStorage.getItem('first_login');
    if (firstLogin === 'true') {
      setIsFirstLogin(true);
    }
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) return <LoadingScreen loading={true} />;

  return (
    <Box className="main-box-home-dashboard ">
      {isFirstLogin && <WelcomeBanner isFirstLogin />}
      <Box className="body-box-hosted-decisions-page">
        <Flex justify="space-between" align="center" mb={4}>
          <Flex
            textAlign="left"
            mb={2}
            justifyContent="flex-start"
            alignItems="center"
            width="100%"
          >
            <Circle
              size="60px"
              className="group-identification-circle"
              color="white"
              mr={4}
            >
              {currentGroup.title.charAt(0)?.toUpperCase()}
            </Circle>
            <Box>
              <Text className="group-card-header">{currentGroup.title}</Text>
              {currentGroup.url && (
                <Link
                  className="group-card-link"
                  href={currentGroup.url}
                  target="_blank"
                >
                  {currentGroup.url}
                </Link>
              )}
            </Box>
          </Flex>
          {isAdmin && (
            <Button
              className="main-button"
              py={4}
              px={6}
              height="100%"
              minWidth="max-content"
              onClick={handleOpenModal}
            >
              {currentTab === 0 && t('tooltip.edit')}
              {currentTab === 1 && t('btn.decision.create_new')}
              {(currentTab === 2 || currentTab === 3) &&
                t('group.invite_member')}
            </Button>
          )}
        </Flex>
        <Tabs
          index={currentTab}
          onChange={(index) => setCurrentTab(index)}
          variant="unstyled"
        >
          <TabList
            display="flex"
            justifyContent="space-around"
            w={'fit-content'}
            mb={4}
            className="tab-list-hosted-decisions-page"
          >
            <Tab
              className="tab-modal-invite-participants"
              _selected={{
                borderBottom: '3px var(--MAIN-GREY) solid !important',
                outline: 'none !important',
              }}
            >
              {t('tab.description')}
            </Tab>
            <Tab
              className="tab-modal-invite-participants"
              _selected={{
                borderBottom: '3px var(--MAIN-GREY) solid !important',
                outline: 'none !important',
              }}
            >
              {t('sidebar.link.decisions')}
            </Tab>
            {isAdmin && (
              <>
                <Tab
                  className="tab-modal-invite-participants"
                  _selected={{
                    borderBottom: '3px var(--MAIN-GREY) solid !important',
                    outline: 'none !important',
                  }}
                >
                  {t('tab.members')}
                </Tab>
                <Tab
                  className="tab-modal-invite-participants"
                  _selected={{
                    borderBottom: '3px var(--MAIN-GREY) solid !important',
                    outline: 'none !important',
                  }}
                >
                  {t('tab.invites')}
                </Tab>
              </>
            )}
          </TabList>
          {currentTab !== 0 && (
            <Flex
              className="flex-participate-participate-page"
              px={'22px'}
              gap={4}
            >
              <Flex className="first-box-participate-page">
                <InputGroup>
                  <InputRightElement>
                    <Image
                      src={searchIcon}
                      className="input-right-element-image-create-decision-page"
                      alt={t('input.placeholder.search')}
                    />
                  </InputRightElement>
                  <Input
                    className="input-participate-page"
                    placeholder={t('input.placeholder.search')}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </InputGroup>
              </Flex>
              <Flex className="inside-flex-participate-page" gap={4}>
                <Box className="input-box-participate-page">
                  <InputGroup gap={4}>
                    {currentTab === 1 && (
                      <Select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className="select-participate-page"
                      >
                        {listOfStatus.map((currentStatus, i) => (
                          <option key={i} value={currentStatus.value}>
                            {currentStatus.label}
                          </option>
                        ))}
                      </Select>
                    )}
                    <Select
                      value={sortBy}
                      onChange={(e) => setSortBy(e.target.value)}
                      className="select-participate-page"
                    >
                      {returnListOfSortsData(currentTab).map(
                        (currentSort, i) => (
                          <option key={i} value={currentSort.value}>
                            {currentSort.label}
                          </option>
                        ),
                      )}
                    </Select>
                  </InputGroup>
                </Box>
              </Flex>
            </Flex>
          )}

          <TabPanels>
            <TabPanel>
              <GroupDescription currentGroup={currentGroup} />
            </TabPanel>
            <TabPanel>
              <GroupsDecisions
                search={search}
                sortBy={sortBy}
                status={status}
                isAdmin={isAdmin}
              />
            </TabPanel>
            {isAdmin && (
              <TabPanel style={{ padding: '0' }}>
                <TableOfGroupMembers search={search} sortBy={sortBy} />
              </TabPanel>
            )}
            {isAdmin && (
              <TabPanel style={{ padding: '0' }}>
                <TableOfGroupInvites search={search} sortBy={sortBy} />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Box>
      <ModalEditGroup
        isOpen={isEditGroupOpen}
        onClose={onEditGroupClose}
        onDeleteGroupOpen={onDeleteGroupOpen}
      />
      <ModalInviteGroup
        isOpen={isInviteGroupOpen}
        onClose={onInviteGroupClose}
        groupId={currentGroup.id}
      />
      <ModalCreateGroupDecision
        isOpen={isCreateDecisionOpen}
        onClose={onCreateDecisionClose}
      />
      <ModalDeleteGroup
        isOpen={isDeleteGroupOpen}
        onClose={onDeleteGroupClose}
      />
    </Box>
  );
}
