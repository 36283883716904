import { useState } from 'react';
import { Button, Box, Center, Spinner } from '@chakra-ui/react';
import useMarkdownTranslations from '../../../translations/i18nextConfig/useMarkdownTranslations';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import groupsApi from 'api/groups';
import { getErrorMessage } from 'utils';

const JoinWithAccount = ({ redirectUser }) => {
  const { currentGroup, fetchGroupsAndInvitations } = useCurrentGroupsContext();
  const { t } = useMarkdownTranslations();

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const acceptInvitation = async () => {
    setIsLoading(true);
    try {
      const { data, included } = await groupsApi.acceptGroupInvite(
        currentGroup.id,
      );
      await fetchGroupsAndInvitations();
      redirectUser(data, included);
      setIsLoading(false);
    } catch (e) {
      const error = e?.data?.errors?.[0]?.detail;
      if (error) setErrorMessage(t(getErrorMessage(error)));
      setIsLoading(false);
    }
  };

  if (!currentGroup) return null;

  return (
    <Box>
      <Center>
        <Button className="accept-btn" onClick={() => acceptInvitation()}>
          {isLoading ? <Spinner /> : t('btn.accept_invitation')}
        </Button>
      </Center>
    </Box>
  );
};

export default JoinWithAccount;
