import React from 'react';
import {
  Box,
  Input,
  Select,
  Flex,
  InputGroup,
  InputRightElement,
  Image,
} from '@chakra-ui/react';
import searchIcon from '../assets/images/search.png';
import useMarkdownTranslations from '../translations/i18nextConfig/useMarkdownTranslations';

export default function Filters({ search, sortBy, setSearch, setSortBy }) {
  const { t } = useMarkdownTranslations();

  const listOfSorts = [
    { label: t('decision.sort.by'), value: 'none' },
    { label: t('decision.sort.title'), value: 'group_title' },
  ];

  return (
    <Flex className="flex-participate-participate-page" gap={4}>
      <Box className="first-box-participate-page" flex="0 0 88%">
        <InputGroup>
          <InputRightElement>
            <Image
              src={searchIcon}
              className="input-right-element-image-create-decision-page"
              alt={t('input.placeholder.search')}
            />
          </InputRightElement>
          <Input
            className="input-participate-page"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t('input.placeholder.search')}
          />
        </InputGroup>
      </Box>
      <Box flex="0 0 10%">
        <InputGroup>
          <Select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            className="select-participate-page"
          >
            {listOfSorts.map((currentSort, i) => (
              <option key={i} value={currentSort.value}>
                {currentSort.label}
              </option>
            ))}
          </Select>
        </InputGroup>
      </Box>
    </Flex>
  );
}
