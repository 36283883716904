import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Text,
  Flex,
  Input,
  FormControl,
  FormLabel,
  SimpleGrid,
  Box,
  Spinner,
} from '@chakra-ui/react';
import groupsApi from 'api/groups';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import { useNavigate } from 'react-router-dom';

export default function ModalDeleteGroup({ isOpen, onClose }) {
  const { t } = useMarkdownTranslations();
  const { fetchGroups, currentGroup } = useCurrentGroupsContext();
  const [groupTitle, setGroupTitle] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setGroupTitle('');
    setErrorTitle('');
  }, [currentGroup]);

  const handleDelete = async () => {
    setIsLoading(true);
    if (currentGroup.title !== groupTitle) {
      setErrorTitle(t('errors.title.mismatch'));
      setIsLoading(false);
      return;
    } else setErrorTitle('');

    try {
      await groupsApi.deleteGroup(currentGroup.id);
      await fetchGroups(currentGroup.id);
      setIsLoading(false);
      handleClose();
    } catch (error) {
      //todo: handle error
    }
  };

  const handleClose = () => {
    onClose();
    navigate('/user/groups/hosting', { replace: true });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius="16px"
        maxWidth={{ base: '30%', sm: '80%', md: '30%', lg: '30%' }}
        padding={4}
      >
        <ModalBody paddingY={4}>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            alignItems="center"
          >
            <Text
              fontSize="lg"
              fontWeight="bold"
              color="var(--DANGER-COLOR)"
              mb={0}
            >
              {t('group.are_you_sure')}
            </Text>
            <Text fontSize="sm" color="gray.600">
              {t('group.delete.warning')}
            </Text>
          </Box>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
            <FormControl
              gridColumn={{ base: 'span 1', md: 'span 3' }}
              position="relative"
            >
              <Box display="flex" justifyContent="space-between">
                <FormLabel htmlFor="group-name" className="input-label-texts">
                  {t('group.delete.instructions')}
                </FormLabel>
              </Box>
              <Input
                type="text"
                onChange={(e) => setGroupTitle(e.target.value)}
                value={groupTitle}
                id="group-name"
                className="input-modal-invite-participants"
              />
              {errorTitle && (
                <Text className="required-field">{errorTitle}</Text>
              )}
            </FormControl>
          </SimpleGrid>
        </ModalBody>
        <ModalFooter
          borderTop="1px"
          borderColor="gray.300"
          margin={0}
          pt={0}
          marginBottom={2}
        >
          <Box
            w="100%"
            display="flex"
            flexDirection="column"
            gap={6}
            marginTop={4}
          >
            <Flex w="100%" justifyContent="space-between" gap={4}>
              <Button
                className="first-button-modal-invite"
                onClick={() => onClose()}
              >
                {t('btn.go_back')}
              </Button>
              <Button
                className="second-button-modal-invite"
                onClick={() => handleDelete()}
              >
                {isLoading ? <Spinner /> : t('btn.confirm')}
              </Button>
            </Flex>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
