import { useReducer } from 'react';
import {
  decisionsInitState,
  decisionsReducer,
} from 'features/decisions/reducer';
import apiDecision from 'api/decisions';
import { DECISIONS } from 'features/decisions/actions';
import moment from 'moment-timezone';
import { normalizeDecisionsTemplates, normalizeRoleDecisions } from 'utils/normalize';

export default function useCurrentTable() {
  const [state, dispatch] = useReducer(decisionsReducer, decisionsInitState);

  const fetchDecisionsTemplates = async () => {
    try {
      const { data } = await apiDecision.getDecisionsTemplates();
      const templates = normalizeDecisionsTemplates(data)

      dispatch({
        type: DECISIONS.DECISIONS_TEMPLATES,
        payload: templates,
      });
    } catch (error) {
      dispatch({
        type: DECISIONS.DECISIONS_TEMPLATES,
        payload: {
          templates: [],
        },
      });
    }
  };

  const fetchInvitedToHostDecisions = async () => {
    try {
      const { data } =
        await apiDecision.getInvitedToHostDecisions();
      const invitedDecisions = normalizeRoleDecisions(data)

      dispatch({
        type: DECISIONS.TABLE_DECISIONS_INVITED_TO_HOST,
        payload: invitedDecisions,
      });
    } catch (error) {
      dispatch({
        type: DECISIONS.TABLE_DECISIONS_INVITED_TO_HOST,
        payload: {
          dataOfTable: [],
        },
      });
    }
  };

  const fetchInvitedToParticipateDecisions = async () => {
    try {
      const { data } =
        await apiDecision.getInvitedToParticipateDecisions();
      const invitedDecisions = normalizeRoleDecisions(data)

      dispatch({
        type: DECISIONS.TABLE_DECISIONS_INVITED_TO_PARTICIPATE,
        payload: invitedDecisions,
      });
    } catch (error) {
      dispatch({
        type: DECISIONS.TABLE_DECISIONS_INVITED_TO_PARTICIPATE,
        payload: {
          dataOfTable: [],
        },
      });
    }
  };

  const fetchParticipatingDecisions = async () => {
    try {
      const { data } =
        await apiDecision.getParticipatingDecisions();
      const participatingDecisions = normalizeRoleDecisions(data)

      dispatch({
        type: DECISIONS.TABLE_DECISIONS_PARTICIPATING,
        payload: participatingDecisions,
      });
    } catch (error) {
      dispatch({
        type: DECISIONS.TABLE_DECISIONS_PARTICIPATING,
        payload: {
          dataOfTable: [],
        },
      });
    }
  };

  const fetchHostingDecisions = async () => {
    try {
      const { data } = await apiDecision.getHostingDecisions();
      const hostingDecisions = normalizeRoleDecisions(data)

      dispatch({
        type: DECISIONS.TABLE_DECISIONS_HOSTING,
        payload:
          hostingDecisions,
      });
    } catch (error) {
      dispatch({
        type: DECISIONS.TABLE_DECISIONS_HOSTING,
        payload: {
          hostingDecisions: [],
        },
      });
    }
  };

  // Get the data of decisions where the user is a host or is invited to host
  const getHostDecisions = async () => {
    try {
      await fetchInvitedToHostDecisions();
      await fetchHostingDecisions();
    } catch (error) {
      //todo: handle error
    }
  };

  // Get the data of decisions where the user is a participant or is invited to participate
  const getParticipationDecisions = async () => {
    try {
      await fetchInvitedToParticipateDecisions();
      await fetchParticipatingDecisions();
    } catch (error) {
      //todo: handle error
    }
  };

  const getDecisionsData = async () => {
    try {
      await getParticipationDecisions();
      await getHostDecisions();
    } catch (error) {
      //todo: handle error
    }
  };

  const getDecisions = async () => {
    try {
      await fetchDecisionsTemplates();
      await getDecisionsData();
      dispatch({
        type: DECISIONS.DECISIONS,
        payload: true,
      });
    } catch (error) {
      //todo: handle error
    }
  };

  const convertDate = (dateStr) => {
    const momentOriginal = moment(dateStr, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true);

    if (momentOriginal.isValid()) {
      const formattedDate = momentOriginal.format('MM/DD/YYYY');
      const formattedTime = momentOriginal.format('hh:mm A');
      return [formattedDate, formattedTime];
    }

    const momentWithOffset = moment(dateStr, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true);

    if (momentWithOffset.isValid()) {
      const formattedDate = momentWithOffset.format('MM/DD/YYYY');
      const formattedTime = momentWithOffset.format('hh:mm A');
      return [formattedDate, formattedTime];
    }

    return dateStr;
  };

  const setCreateDecisionData = (decisionData, isTemplate) => {
    decisionData.isTemplate = isTemplate;
    dispatch({
      type: DECISIONS.CREATE_DECISION_DATA,
      payload: {
        decisionData,
      },
    });
  };

  const setEditDecisionUrl = (url) => {
    dispatch({
      type: DECISIONS.EDIT_DECISION_URL,
      payload: {
        url,
      },
    });
  };

  return {
    ...state,
    setEditDecisionUrl,
    setCreateDecisionData,
    getDecisions,
    convertDate,
  };
}
