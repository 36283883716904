import React, { useContext } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Text,
  Flex,
  Box,
} from '@chakra-ui/react';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import groupsApi from 'api/groups';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';
import notify from 'common/utils/notify';

export default function ModalLeaveGroup({ isOpen, onClose, groupId }) {
  const { fetchGroups } = useCurrentGroupsContext();
  const { t } = useMarkdownTranslations();
  const leaveGroup = async () => {
    try {
      await groupsApi.leaveGroup(groupId);
      notify('none', t('group.leave.successfully'));
      fetchGroups();
    } catch (e) {
      notify('error', e.data.errors[0].title);
    } finally {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius="16px"
        maxWidth={{ base: '30%', sm: '80%', md: '30%', lg: '30%' }}
        padding={3}
      >
        <ModalBody paddingY={4}>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            alignItems="center"
          >
            <Text fontSize="lg" fontWeight="bold" mb={0}>
              {t('group.leave')}
            </Text>
            <Text fontSize="sm" color="gray.600">
              {t('group.leave.warning')}
            </Text>
          </Box>
        </ModalBody>
        <ModalFooter borderTop="1px" borderColor="gray.300" margin={0} pt={0}>
          <Flex w="100%" justifyContent="space-between" gap={3} marginTop={7}>
            <Button className="main-button" flex={1} onClick={() => onClose()}>
              {t('btn.cancel')}
            </Button>
            <Button
              className="danger-button"
              flex={1}
              onClick={() => leaveGroup()}
            >
              {t('btn.leave')}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
