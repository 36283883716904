import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  Input,
  FormControl,
  Text,
  Flex,
  Box,
  Spinner,
} from '@chakra-ui/react';
import groupsApi from '../../../api/groups';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import { t } from 'i18next';
import { getTimezoneObject } from 'utils/time';
import { getErrorMessage } from 'utils';
import { useNavigate } from 'react-router-dom';

const ModalCreateGroup = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { fetchGroups } = useCurrentGroupsContext();
  const [title, setTitle] = useState('');
  const [slug, setSlug] = useState('');
  const [url, setUrl] = useState('');
  const [info, setInfo] = useState('');
  const [language, setLanguage] = useState(null);
  const [timeZone, setTimeZone] = useState(getTimezoneObject('UTC'));
  const [titleError, setTitleError] = useState('');
  const [slugError, setSlugError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(getTimezoneObject(userTimezone));
  }, []);

  const handleClose = () => {
    setTitle('');
    setSlug('');
    setUrl('');
    setInfo('');
    setLanguage('');
    setTimeZone(getTimezoneObject('UTC'));
    setTitleError('');
    setSlugError('');
    setIsLoading(false);
    onClose();
  };

  const handleCreate = async () => {
    setIsLoading(true);

    if (!!title) setTitleError('');
    else setTitleError(t(getErrorMessage('errors.messages.blank')));

    if (!!slug) setSlugError('');
    else setSlugError(t(getErrorMessage('errors.messages.blank')));

    if (!slug || !title) {
      setIsLoading(false);
      return;
    }

    const groupData = {
      title,
      slug,
      url,
      info,
      language: language?.value,
      time_zone: timeZone.value,
    };

    groupsApi
      .createGroup(groupData)
      .then(() => {
        localStorage.setItem('newGroup', true);
        fetchGroups();
        handleClose();
        setIsLoading(false);
        navigate(`/g/${slug}`);
      })
      .catch((e) => {
        setIsLoading(false);
        e?.data?.errors?.forEach((currentError) => {
          switch (currentError.source.pointer) {
            case '/data/attributes/title':
              setTitleError(t(getErrorMessage(currentError.detail)));
              break;
            case '/data/attributes/slug':
              setSlugError(t(getErrorMessage(currentError.detail)));
              break;
            default:
              break;
          }
        });
        console.error('Error creating group:', e.data.errors);
      });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        size={{ base: 'md', md: 'xl', lg: '3xl' }}
      >
        <ModalOverlay />
        <ModalContent>
          <Text className="modal-header">{t('group.create_new')}</Text>
          <Text className="modal-header-secondary" ml={4}>
            {t('group.functionalities')}
          </Text>
          <ModalBody p={4}>
            <Flex gap={4}>
              <FormControl mb={4} flex="0.30">
                <Flex className="flex-password-without-metamask">
                  <Box direction="column">
                    <Text className="input-form-label">{t('group.name')}</Text>
                  </Box>
                  <Box direction="column" className="required-field">
                    *
                  </Box>
                </Flex>
                <Input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="basic-input"
                />
                {titleError && (
                  <Text className="required-field">{titleError}</Text>
                )}
              </FormControl>
              <FormControl mb={4} flex="0.70">
                <Flex className="flex-password-without-metamask">
                  <Box direction="column">
                    <Text className="input-form-label">
                      {t('group.identifier_info')}
                    </Text>
                  </Box>
                  <Box direction="column" className="required-field">
                    *
                  </Box>
                </Flex>

                <Input
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                  className="basic-input"
                />
                {slugError && (
                  <Text className="required-field">{slugError}</Text>
                )}
              </FormControl>
            </Flex>

            <FormControl mb={4}>
              <Text className="input-form-label">
                {t('group.url.more_info')}
              </Text>
              <Input
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                className="basic-input"
              />
            </FormControl>
            <Flex gap={3}>
              <Button
                className="secondary-button"
                py={4}
                px={6}
                height="100%"
                onClick={handleClose}
                isDisabled={isLoading}
                flex="1"
              >
                {t('btn.cancel')}
              </Button>
              <Button
                onClick={handleCreate}
                py={4}
                px={6}
                height="100%"
                className="main-button"
                flex="1"
                isDisabled={isLoading}
              >
                {isLoading ? <Spinner /> : t('btn.group.create')}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalCreateGroup;
