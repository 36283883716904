import React from 'react';
import { Card, Flex, Stack, CardBody, Text, Avatar } from '@chakra-ui/react';
import './styles.css';
import { useCurrentUserContext } from 'features/user/contexts/user-provider';
import './styles.css';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';

export default function AccountHeader() {
  const { t } = useMarkdownTranslations();
  const { user } = useCurrentUserContext();

  return (
    <Card variant="outline" className="card-account-header">
      <Flex className="flex-account-header">
        <Avatar src={user?.avatar} className="avatar-account-header" />
        <Stack role="main">
          <CardBody>
            <Text className="username-account-header">{user?.name}</Text>
            <Text className="user-title-account-header">
              {t(`account.admin`)}
            </Text>
          </CardBody>
        </Stack>
      </Flex>
    </Card>
  );
}
