import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  Box,
  Spinner,
} from '@chakra-ui/react';
import logo from '../../../assets/images/polity_dark_mode2.png';
import groupsApi from 'api/groups';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import useMarkdownTranslations from '../../../translations/i18nextConfig/useMarkdownTranslations';

const ModalDeleteMember = ({ isOpen, onClose, user, groupId, invite }) => {
  const { fetchInvitesOfGroup, fetchUsersOfGroup } = useCurrentGroupsContext();
  const { t } = useMarkdownTranslations();
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      if (invite) {
        await groupsApi.deleteInvitationFromGroup(invite.id);
        await fetchInvitesOfGroup(groupId);
      } else {
        await groupsApi.deleteUserFromGroup(user.groupUserId);
        await fetchUsersOfGroup(groupId);
      }
      setIsLoading(false);
      onClose();
    } catch (error) {
      setIsLoading(false);
      //todo: handle error
    }
  };
  return (
    <Modal padding={'0px'} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="18px" p={4}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={2}
          borderBottom="1px"
          borderColor="gray.300"
        >
          <img src={logo} alt="logo" width={126} height={23} />
          <Text fontSize="lg" fontWeight="bold" mb={0}>
            {t('group.delete.are_you_sure')}
          </Text>
          <Text fontSize="sm" color="gray.600">
            {t('group.delete.click_below')}
          </Text>
        </Box>

        <ModalBody>
          <Box display="flex" alignItems="center" gap={2}>
            <Text mt={2} fontWeight="bold" fontSize="sm">
              {t('group.invite.fields.name')}
            </Text>
            <Text mt={2} fontSize="sm">
              {invite ? invite.user.name : user.name}
            </Text>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Text mt={2} fontWeight="bold" fontSize="sm">
              {t('group.invite.fields.email')}
            </Text>
            <Text mt={2} fontSize="sm">
              {invite ? invite.user.email : user.email}
            </Text>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Text mt={2} fontWeight="bold" fontSize="sm">
              {t('group.invite.fields.wallet')}
            </Text>
            <Text mt={2} fontSize="sm">
              {invite ? invite.user.publicAddress : user.publicAddress}
            </Text>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Text mt={2} fontWeight="bold" fontSize="sm">
              {t('group.invite.fields.role')}
            </Text>
            <Text mt={2} fontSize="sm">
              {invite ? invite.user.roles : user.roles}
            </Text>
          </Box>
        </ModalBody>

        <ModalFooter gap={3}>
          <Button
            onClick={onClose}
            width="70%"
            border="1px solid var(--MAIN-GREY)"
            backgroundColor="var(--CLEAR-BACKGROUND)"
            className="secondary-button"
          >
            {t('btn.go_back')}
          </Button>
          <Button className="danger-button" onClick={handleDelete} width="30%">
            {isLoading ? <Spinner /> : t('btn.i_am_sure')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalDeleteMember;
