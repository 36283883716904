import { extendTheme } from '@chakra-ui/react';

const fonts = {
  ubuntuFont: 'Ubuntu',
  interFont: 'Inter',
};

const theme = extendTheme({
  config: {
    initialColorMode: 'light',
  },
  fonts,
  components: {
    Button: {
      baseStyle: {
        _active: {
          boxShadow: 'none',
          border: 'none',
          outline: 'none',
        },
        _focus: {
          boxShadow: 'none',
          border: 'none',
          outline: 'none',
        },
      },
    },
    Td: {
      baseStyle: {
        wordWrap: 'break-all',
      },
    },
  },
  styles: {
    global: (props) => ({
      body: {
        color: 'black',
      },
    }),
  },
});

const darkTheme = extendTheme({
  config: {
    initialColorMode: 'dark',
  },
  fonts,
  components: {
    Button: {
      baseStyle: {
        _active: {
          boxShadow: 'none',
          border: 'none',
          outline: 'none',
        },
        _focus: {
          boxShadow: 'none',
          border: 'none',
          outline: 'none',
        },
      },
    },
  },
  styles: {
    global: (props) => ({
      body: {
        color: 'white',
      },
    }),
  },
});

export { theme, darkTheme };
