import { DECISIONS } from 'features/decisions/actions';

export const decisionsInitState = {
  participatingDecisions: [],
  hostingDecisions: [],
  invitedToParticipateDecisions: [],
  invitedToHostDecisions: [],
  decisionsTemplates: [],
  createDecisionData: {},
  editDecisionUrl: null,
  decisions: false,
};

/* eslint-disable no-fallthrough */
export const decisionsReducer = (state = decisionsInitState, action) => {
  switch (action.type) {
    case DECISIONS.DECISIONS_TEMPLATES:
      return {
        ...state,
        decisionsTemplates: action.payload,
      };
    case DECISIONS.TABLE_DECISIONS_PARTICIPATING:
      return {
        ...state,
        participatingDecisions: action.payload,
      };
    case DECISIONS.TABLE_DECISIONS_INVITED_TO_PARTICIPATE:
      return {
        ...state,
        invitedToParticipateDecisions: action.payload,
      };
    case DECISIONS.TABLE_DECISIONS_HOSTING:
      return {
        ...state,
        hostingDecisions: action.payload,
      };
    case DECISIONS.TABLE_DECISIONS_INVITED_TO_HOST:
      return {
        ...state,
        invitedToHostDecisions: action.payload,
      };
    case DECISIONS.CREATE_DECISION_DATA:
      return {
        ...state,
        createDecisionData: action.payload,
      };
    case DECISIONS.EDIT_DECISION_URL:
      return {
        ...state,
        editDecisionUrl: action.payload,
      };
    case DECISIONS.DECISIONS:
      return {
        ...state,
        decisions: action.payload,
      };
    default:
      return state;
  }
};
