import { useEffect, useReducer } from 'react';
import { userInitState, userReducer } from 'features/user/reducer';
import userApi from 'api/user';
import authApi from 'api/auth';
import { USER } from 'features/user/actions';
import { normalizeUser } from 'utils/normalize';

export default function useCurrentUser() {
  const [state, dispatch] = useReducer(userReducer, userInitState);

  useEffect(() => {
    fetchCurrentUser()
      .then(() => {
        dispatch({
          type: 'IS_LOADED',
          payload: true,
        });
      })
      .catch(() => {
        dispatch({
          type: 'IS_LOADED',
          payload: true,
        });
      });

    /*// eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  const fetchCurrentUser = async () => {
    await userApi
      .getCurrentUser()
      .then(({ data }) => {
        const user = normalizeUser(data);
        cacheAuthorized();
        dispatch({
          type: USER.FETCH.SUCCESS,
          payload: {
            ...user
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: USER.FETCH.ERROR,
          payload: error,
        });
      });
  };

  const cacheAuthorized = () => sessionStorage.setItem('authorized', 'true');
  const clearCache = () => sessionStorage.clear();

  const login = async () => {
    clearCache();
    dispatch({ type: USER.LOGIN.SUCCESS, payload: { authorized: true } });
    cacheAuthorized();
    await fetchCurrentUser();
  };

  const logout = () => {
    clearCache();
    authApi.logout().then(() => {
      window.location.replace('/');
    });
  };

  return {
    ...state,
    login,
    logout,
    fetchCurrentUser,
  };
}
