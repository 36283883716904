import React from 'react';
import { Card, Text } from '@chakra-ui/react';
import PaymentItems from 'components/PaymentItems';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';
import './styles.css';

export default function WalletBody() {
  const { tMarkdown, t } = useMarkdownTranslations();

  return (
    <>
      <Card className="card-main-wallet-body" variant="outline">
        <Text className="product-head-wallet-body">{t('wallet.products')}</Text>
        <Text className="product-body-wallet-body">
          {tMarkdown('wallet.body.text_html')}
        </Text>
        <PaymentItems />
      </Card>
    </>
  );
}
