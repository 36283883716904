import moment from 'moment-timezone';

export const timeDifference = (timeString) => {
  const time = new Date(timeString);
  const now = new Date();
  const difference = time - now;

  const absDifference = Math.abs(difference);

  const days = Math.floor(absDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(absDifference / (1000 * 60 * 60)) % 24;
  const minutes = Math.floor(absDifference / (1000 * 60)) % 60;
  const seconds = Math.floor(absDifference / 1000) % 60;

  let result = '';
  if (difference > 0) {
    if (days > 0) {
      result = `${days} Day${days > 1 ? 's' : ''} Left`;
    } else if (hours > 0) {
      result = `${hours} Hour${hours > 1 ? 's' : ''} Left`;
    } else if (minutes > 0) {
      result = `${minutes} Minute${minutes > 1 ? 's' : ''} Left`;
    } else {
      result = `${seconds} Second${seconds > 1 ? 's' : ''} Left`;
    }
  } else {
    if (days > 0) {
      result = `${days} Day${days > 1 ? 's' : ''} Ago`;
    } else if (hours > 0) {
      result = `${hours} Hour${hours > 1 ? 's' : ''} Ago`;
    } else if (minutes > 0) {
      result = `${minutes} Minute${minutes > 1 ? 's' : ''} Ago`;
    } else {
      result = `${seconds} Second${seconds > 1 ? 's' : ''} Ago`;
    }
  }
  return result;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  function pad(number) {
    return number < 10 ? '0' + number : number;
  }

  const day = pad(date.getUTCDate());
  const month = pad(date.getUTCMonth() + 1);
  const year = date.getUTCFullYear();

  let hours = date.getUTCHours();
  const minutes = pad(date.getUTCMinutes());

  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;

  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes} ${ampm} UTC`;

  return formattedDate;
};

export const formatDateToMMYYYY = (isoString) => {
  const date = new Date(isoString);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${year}`;
};

export const formatDateToDDMMYYYY = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getTimezonesInFormat = () => {
  const timezones = moment.tz.names();
  return timezones
    .map((timezone) => {
      const currentTime = moment.tz(timezone);
      const offsetHours = currentTime.utcOffset() / 60;
      return {
        timezone,
        offsetHours,
      };
    })
    .sort((a, b) => a.offsetHours - b.offsetHours)
    .map(({ timezone, offsetHours }) => ({
      value: timezone,
      label: `(GMT${offsetHours >= 0 ? `+${offsetHours}` : offsetHours
        }) ${timezone}`,
    }));
};

export const getTimezoneObject = (timezone) => {
  const currentTime = moment.tz(timezone);
  const offsetHours = currentTime.utcOffset() / 60;
  return {
    value: timezone,
    label: `(GMT${offsetHours >= 0 ? `+${offsetHours}` : offsetHours
      }) ${timezone}`,
  };
};