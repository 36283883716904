// src/hooks/useMarkdownTranslation.js
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import {
  UnorderedList,
  ListItem,
  Text,
  Flex,
  Box,
  Link,
} from '@chakra-ui/react';

const useMarkdownTranslation = () => {
  const { t, i18n } = useTranslation();

  const tMarkdown = (key, options = {}) => {
    const translation = t(key, options);

    return (
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        components={{
          p: ({ node, ...props }) => <Text {...props} />,
          ul: ({ node, ...props }) => (
            <UnorderedList styleType="none" {...props} />
          ),
          li: ({ node, ...props }) => (
            <ListItem mb={2}>
              <Flex alignItems="flex-start">
                <Box className="li-circle-mark" />
                <Text>{props.children}</Text>
              </Flex>
            </ListItem>
          ),
          a: ({ node, ...props }) => (
            <Link className="terms-link" {...props}>
              {props.children}
            </Link>
          ),
          strong: ({ node, ...props }) => (
            <Text as="strong" fontWeight="bold" {...props} />
          ),
        }}
      >
        {translation}
      </ReactMarkdown>
    );
  };

  return { t, tMarkdown, i18n };
};

export default useMarkdownTranslation;
