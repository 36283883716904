import React, { useEffect, useState } from 'react';
import {
  Text,
  Flex,
  Input,
  Checkbox,
  FormControl,
  FormLabel,
  SimpleGrid,
  Tooltip,
  Box,
  InputGroup,
  Select,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';
import useMarkdownTranslations from '../translations/i18nextConfig/useMarkdownTranslations';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import SentEmailCheckBox from './SendEmailCheckBox';

const options = [
  { value: 'admin', label: 'account.admin' },
  { value: 'moderator', label: 'account.moderator' },
  { value: 'member', label: 'account.member' },
];

export default function ModalInviteUser({
  setEmailOrWallet,
  setLinkDecision,
  emailOrWallet,
  linkDecision,
  setSendEmail,
  setFirstName,
  errorMessage,
  firstName,
  setRole,
  role,
}) {
  const { decisionGroups } = useCurrentGroupsContext();
  const { t } = useMarkdownTranslations();

  const [decisionsList, setDecisionsList] = useState([]);

  useEffect(() => {
    // Map decisionGroups to the desired format
    const mappedData = decisionGroups.map((decision) => {
      return {
        value: decision.id,
        label: decision.title,
      };
    });
    const finalDecisionList = [
      { value: 'none', label: 'Select Decision' },
      ...mappedData,
    ];

    setDecisionsList(finalDecisionList);
  }, [decisionGroups]);

  return (
    <>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mt={4}>
        <FormControl gridColumn={{ base: 'span 1', md: 'span 1' }}>
          <FormLabel htmlFor="first-name" className="input-label-text">
            <Flex justifyContent="space-between" alignItems="center">
              <span>{t('participant.name')}</span>
              <span>*</span>
            </Flex>
          </FormLabel>
          <Input
            id="first-name"
            className="input-modal-invite-participants"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </FormControl>
        <FormControl gridColumn={{ base: 'span 1', md: 'span 1' }}>
          <FormLabel htmlFor="email" className="input-label-text">
            <Flex justifyContent="space-between" alignItems="center">
              <span>{t('input.header.email_or_wallet')}</span>
              <span>*</span>
            </Flex>
          </FormLabel>
          <Input
            id="email"
            className="input-modal-invite-participants"
            value={emailOrWallet}
            onChange={(e) => setEmailOrWallet(e.target.value)}
          />
          {errorMessage && (
            <Text className="error-text-without-metamask">{errorMessage}</Text>
          )}
        </FormControl>
        <FormControl
          gridColumn={{ base: 'span 1', md: 'span 2' }}
          position="relative"
        >
          <Box display="flex" justifyContent="space-between">
            <FormLabel htmlFor="influence" className="input-label-texts">
              {t('group.invite.decision.link')}
            </FormLabel>
          </Box>
          <Flex className="inside-flex-participate-page" gap={4}>
            <Box className="input-box-participate-page">
              <InputGroup gap={4}>
                <Select
                  value={linkDecision}
                  onChange={(e) => setLinkDecision(e.target.value)}
                  className="select-participate-page"
                >
                  {decisionsList.map((decision) => (
                    <option key={decision.value} value={decision.value}>
                      {decision.label}
                    </option>
                  ))}
                </Select>
              </InputGroup>
            </Box>
          </Flex>
        </FormControl>
      </SimpleGrid>
      <SentEmailCheckBox setSendEmail={setSendEmail} />
      <Flex align="center" mt={1} mb={2}>
        <Text mt={1} mb={1} className="roles-modal-invite-participants">
          {t('participant.role.plural')}
        </Text>
        <Tooltip
          label={t('tooltip.roles')}
          aria-label={t('accessibility.information.roles')}
          placement="top-end"
          hasArrow
        >
          <Box
            as="span"
            ml={2}
            cursor="pointer"
            fontWeight="bold"
            color="white"
            _hover={{ color: 'white' }}
            background="black"
            borderRadius="full"
            height={6}
            width={6}
            textAlign="center"
            lineHeight="24px"
          >
            !
          </Box>
        </Tooltip>
      </Flex>
      <RadioGroup
        onChange={setRole}
        value={role}
        className="checkbox-text-modal-invite-participants"
      >
        <Flex direction="row" gap={4}>
          {options.map((option) => (
            <Radio
              key={option.value}
              value={option.value}
              size="lg"
              className="checkbox-text-modal-invite-participants"
              sx={{
                'span.chakra-radio__control': {
                  width: '24px',
                  height: '24px',
                  backgroundColor: 'var(--INPUT-BACKGROUND)',
                  boxShadow: 'none',
                  _checked: {
                    bg: 'var(--INPUT-BACKGROUND)',
                    color: 'var(--MAIN-GREY)',
                  },
                },
                'span.chakra-radio__control[data-checked]': {
                  backgroundColor: 'var(--INPUT-BACKGROUND)',
                  color: 'var(--MAIN-GREY)',
                },
                'span.chakra-radio__control:focus': {
                  boxShadow: 'none',
                },
              }}
            >
              <span className="checkbox-text-modal-invite-participants">
                {t(option.label)}
              </span>
            </Radio>
          ))}
        </Flex>
      </RadioGroup>
    </>
  );
}
