import Client from './client';

const api = () => {
  return {
    getInvitedToHostDecisions: () => {
      return Client.get('member/decisions/assigned');
    },
    getInvitedToParticipateDecisions: () => {
      return Client.get('member/decisions/invited');
    },
    getParticipatingDecisions: () => {
      return Client.get('member/decisions/participating');
    },
    getHostingDecisions: () => {
      return Client.get('member/decisions/hosting');
    },
    getDecisionsTemplates: () => {
      return Client.get('member/groups/1/templates');
    },
    createDecisions: (data) => {
      return Client.post('member/decisions/copy.json', data);
    },
  };
};

export default api();
