export const dataOfTable = [
  {
    id: 0,
    name: 'John Doe',
    email: 'john@example.com',
    web3Address: '0x1234abcd5678efgh9101ijklmn2134opqr5678st',
    status: 'Active',
    roles: 'Admin',
    infl: 1,
    inviteSent: '2024-09-25',
    lastSignIn: '2024-09-30',
  },
  {
    id: 1,
    name: 'Jane Smith',
    email: 'jane@example.com',
    web3Address: '0x5678efgh9101ijkl2134mnopqr5678abcd1234st',
    status: 'Pending',
    roles: 'User',
    infl: 1,
    inviteSent: '2024-08-15',
    lastSignIn: '2024-08-20',
  },
  {
    id: 2,
    name: 'Michael Brown',
    email: 'michael.brown@example.com',
    web3Address: '0x2234abcd1234efgh5678ijklmn9101opqr5678st',
    status: 'Active',
    roles: 'User',
    infl: 1,
    inviteSent: '2024-07-10',
    lastSignIn: '2024-07-15',
  },
  {
    id: 3,
    name: 'Emily Davis',
    email: 'emily.davis@example.com',
    web3Address: '0x9876efgh5678abcd9101ijklmn2134opqr5678st',
    status: 'Inactive',
    roles: 'Admin',
    infl: 1,
    inviteSent: '2024-06-01',
    lastSignIn: '2024-06-15',
  },
  {
    id: 4,
    name: 'Chris Johnson',
    email: 'chris.johnson@example.com',
    web3Address: '0x3456ijkl5678abcd1234mnopqr5678efgh9101st',
    status: 'Active',
    roles: 'User',
    infl: 1,
    inviteSent: '2024-05-20',
    lastSignIn: '2024-05-30',
  },
  {
    id: 5,
    name: 'Amanda Clark',
    email: 'amanda.clark@example.com',
    web3Address: '0x4567mnop9101abcd5678ijklmn2134opqr5678st',
    status: 'Pending',
    roles: 'Manager',
    infl: 1,
    inviteSent: '2024-04-10',
    lastSignIn: '2024-04-15',
  },
  {
    id: 6,
    name: 'David Taylor',
    email: 'david.taylor@example.com',
    web3Address: '0x5678efgh9101ijkl2134opqr5678abcd1234mnop',
    status: 'Active',
    roles: 'User',
    infl: 1,
    inviteSent: '2024-03-25',
    lastSignIn: '2024-03-30',
  },
  {
    id: 7,
    name: 'Sophia White',
    email: 'sophia.white@example.com',
    web3Address: '0x6789ijkl1234mnop5678abcd9101opqr5678efgh',
    status: 'Inactive',
    roles: 'Admin',
    infl: 1,
    inviteSent: '2024-02-20',
    lastSignIn: '2024-02-28',
  },
  {
    id: 8,
    name: 'Daniel Lee',
    email: 'daniel.lee@example.com',
    web3Address: '0x7890abcd5678ijkl9101mnopqr1234opqr5678st',
    status: 'Active',
    roles: 'Manager',
    infl: 1,
    inviteSent: '2024-01-15',
    lastSignIn: '2024-01-20',
  },
  {
    id: 9,
    name: 'Olivia Walker',
    email: 'olivia.walker@example.com',
    web3Address: '0x8901ijkl1234mnop5678abcd9101opqr5678efgh',
    status: 'Pending',
    roles: 'User',
    infl: 1,
    inviteSent: '2024-12-01',
    lastSignIn: '2024-12-05',
  },
];
