import { Flex, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import useMarkdownTranslations from '../../../translations/i18nextConfig/useMarkdownTranslations';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import { Separator } from 'components/Separator';
import LoginFields from 'components/LoginFields';

const ResourceNotFound = () => {
  const { currentGroup } = useCurrentGroupsContext();
  const { t } = useMarkdownTranslations();

  return (
    <>
      {currentGroup.url ? (
        <Flex className="input-form-label margin-bottom">
          <span>{t('tooltip.visit')}&nbsp;</span>
          <Link
            as={RouterLink}
            className="input-form-label"
            to={`/g/${currentGroup.url}`}
            _hover={{ textDecoration: 'none' }}
            color={'var(--LINK-COLOR)'}
          >
            {`${currentGroup.url}`}
            &nbsp;
          </Link>
          <span>{t('group.invite.find_contact_info')}</span>
        </Flex>
      ) : (
        <span className="input-form-label margin-bottom">
          {t('group.invite.not_found')}
        </span>
      )}
      <span className="input-form-label">{t('group.invite.signup')}</span>
      <Separator />
      <LoginFields />
    </>
  );
};

export default ResourceNotFound;
