import { useReducer } from 'react';
import { groupsInitState, groupsReducer } from 'features/groups/reducer';
import apiGroups from 'api/groups';
import { GROUPS } from 'features/groups/actions';
import { normalizeDecisions, normalizeGroup, normalizeGroups, normalizeGroupUsers, normalizeInvites } from 'utils/normalize';

export default function useCurrentGroups() {
  const [state, dispatch] = useReducer(groupsReducer, groupsInitState);

  const setDataOfCurrentGroup = (group) => {
    dispatch({
      type: GROUPS.CURRENT_GROUP,
      payload: group,
    });
  };

  const fetchHostedGroups = async (page = 1, sortBy = null, search = null) => {
    try {
      const responseHosted = await apiGroups.getHostedGroups(page, sortBy, search);
      const hostedGroups = normalizeGroups(responseHosted);

      dispatch({
        type: GROUPS.GROUPS_HOSTED,
        payload: { groups: hostedGroups, totalGroups: responseHosted.meta.count },
      });

    } catch (error) {
      dispatch({
        type: GROUPS.GROUPS_HOSTED,
        payload: { groups: [], totalGroups: 0 },
      });
    }
  };

  const fetchJoinedGroups = async (page = 1, sortBy = null, search = null) => {
    try {
      const responseJoined = await apiGroups.getJoinedGroups(page, sortBy, search);
      const joinedGroups = normalizeGroups(responseJoined);

      dispatch({
        type: GROUPS.GROUPS_JOINED,
        payload: { groups: joinedGroups, totalGroups: responseJoined.meta.count },
      });

    } catch (error) {
      dispatch({
        type: GROUPS.GROUPS_JOINED,
        payload: { groups: [], totalGroups: 0 },
      });
    }
  };

  const fetchAllGroups = async (page = 1) => {
    try {
      const responseAllGroups = await apiGroups.getAllGroups(page);
      const allGroups = normalizeGroups(responseAllGroups);

      dispatch({
        type: GROUPS.GROUPS_DATA,
        payload: allGroups,
      });

    } catch (error) {
      dispatch({
        type: GROUPS.GROUPS_DATA,
        payload: [],
      });
    }
  };

  const fetchGroups = async () => {
    fetchHostedGroups()
    fetchJoinedGroups();
    fetchAllGroups();
  };

  const fetchGroupsInvitations = async (page = 1, sortBy = null, search = null) => {
    try {
      const response = await apiGroups.getGroupsInvitations(page, sortBy, search);
      const invitations = normalizeGroups(response);

      dispatch({
        type: GROUPS.GROUPS_INVITATIONS,
        payload: { invitations: invitations, totalInvitations: response.meta.count },
      });
    } catch (error) {
      console.error('Error fetching group invitations:', error);

      dispatch({
        type: GROUPS.GROUPS_INVITATIONS,
        payload: { invitations: [], totalInvitations: 0 },
      });
    }
  };

  const fetchDecisions = async (groupId) => {
    try {
      const { data } = await apiGroups.getGroupDecisions(groupId);
      const decisions = normalizeDecisions(data);
      dispatch({
        type: GROUPS.GROUP_DECISIONS,
        payload: decisions,
      });
    } catch (error) {
      dispatch({
        type: GROUPS.GROUP_DECISIONS,
        payload: {
          decisions: [],
        },
      });
    }
  };

  const fetchGroupBySlug = async (groupSlug) => {
    try {
      const { data } = await apiGroups.getGroupBySlug(groupSlug);
      const group = normalizeGroup(data);

      dispatch({
        type: GROUPS.CURRENT_GROUP,
        payload: group,
      });
    } catch (error) {
      dispatch({
        type: GROUPS.CURRENT_GROUP,
        payload: [],
      });
    }
  };

  const fetchInvitesOfGroup = async (groupId) => {
    try {
      const { data } = await apiGroups.getInvitesOfGroup(groupId);
      const invitations = normalizeInvites(data);

      dispatch({
        type: GROUPS.INVITES_OF_GROUP,
        payload: invitations,
      });
    } catch (error) {
      dispatch({
        type: GROUPS.INVITES_OF_GROUP,
        payload: [],
      });
    }
  };

  const fetchUsersOfGroup = async (groupId) => {
    try {
      const response = await apiGroups.getUsersOfGroup(groupId);
      const users = normalizeGroupUsers(response)

      dispatch({
        type: GROUPS.USERS_OF_GROUP,
        payload: users,
      });
    } catch (error) {
      dispatch({
        type: GROUPS.USERS_OF_GROUP,
        payload: [],
      });
    }
  };

  const fetchGroupsAndInvitations = async () => {
    try {
      await fetchGroups();
      await fetchGroupsInvitations();
    } catch (error) {
      console.error('Error fetching groups and invitations:', error);
    }
  };

  return {
    ...state,
    fetchGroups,
    fetchAllGroups,
    fetchJoinedGroups,
    fetchHostedGroups,
    fetchGroupsInvitations,
    fetchDecisions,
    fetchGroupsAndInvitations,
    setDataOfCurrentGroup,
    fetchInvitesOfGroup,
    fetchUsersOfGroup,
    fetchGroupBySlug,
  };
}
