import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from 'features/user/contexts/user-provider';
import { DecisionsProvider } from 'features/decisions/contexts/decisions-provider';
import { PaymentsProvider } from 'features/payments/contexts/payments-provider';
import { GroupsProvider } from 'features/groups/contexts/groups-provider';
import { I18nextProvider } from 'react-i18next';
import { i18nPromise, i18n } from './translations/i18nextConfig/i18nextConfig';
import { config } from './wagmi';
import { WagmiConfig } from 'wagmi';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <UserProvider>
        <DecisionsProvider>
          <PaymentsProvider>
            <GroupsProvider>
              <BrowserRouter>
                <WagmiConfig config={config}>
                  <App />
                </WagmiConfig>
              </BrowserRouter>
            </GroupsProvider>
          </PaymentsProvider>
        </DecisionsProvider>
      </UserProvider>
    </I18nextProvider>
  </React.StrictMode>,
);
