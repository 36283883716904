import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Button,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import ModalCreateGroup from 'components/GroupAsAdmin/ModalCreateGroup';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import Groups from 'components/Groups';

export default function MyGroups() {
  const {
    fetchHostedGroups,
    groupsHosted,
    fetchJoinedGroups,
    groupsJoined,
    fetchGroupsInvitations,
    groupsInvitations,
  } = useCurrentGroupsContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useMarkdownTranslations();

  // React Router hooks
  const navigate = useNavigate(); // Updated hook
  const location = useLocation();

  // Mapping tab names to indices
  const tabNameToIndex = {
    joined: 0,
    hosting: 1,
    invited: 2,
  };

  const indexToTabName = {
    0: 'joined',
    1: 'hosting',
    2: 'invited',
  };

  // Get current tab from URL
  const pathParts = location.pathname.split('/');
  const currentTabName = pathParts[pathParts.length - 1];
  const [tabIndex, setTabIndex] = useState(tabNameToIndex[currentTabName] || 0);

  // Open modal if URL is /user/groups/create
  useEffect(() => {
    if (location.pathname === '/user/groups/create') {
      onOpen();
    }
  }, [location.pathname, onOpen]);

  // Update tabIndex when URL changes
  useEffect(() => {
    const currentTab = tabNameToIndex[currentTabName];
    if (currentTab !== undefined && currentTab !== tabIndex) {
      setTabIndex(currentTab);
    }
  }, [currentTabName, tabIndex, tabNameToIndex]);

  // Handle tab change
  const handleTabsChange = (index) => {
    setTabIndex(index);
    const tabName = indexToTabName[index];
    navigate(`/user/groups/${tabName}`); // Updated navigation
  };

  const handleModalClose = async () => {
    onClose();
    if (location.pathname !== '/user/groups/create') return;
    navigate('/user/groups/hosting');
  };

  return (
    <>
      <ModalCreateGroup isOpen={isOpen} onClose={handleModalClose} />
      <Box className="main-box-hosted-decisions-page">
        <Box className="body-box-hosted-decisions-page">
          <Flex justify="space-between" align="center" mb={4}>
            <Box>
              <Text fontSize="xl" fontWeight="bold">
                {t('groups')}
              </Text>
              <Text>{t('admin.manage.groups')}</Text>
            </Box>
            <Button
              className="main-button"
              py={4}
              px={6}
              height="100%"
              onClick={() => onOpen()}
            >
              {t('group.create_new')}
            </Button>
          </Flex>
          <Box borderBottom="1px" borderColor="gray.200" mb={4} />
          <Tabs
            index={tabIndex}
            onChange={handleTabsChange}
            variant="unstyled"
            mt={6}
          >
            <TabList className="tab-list-hosted-decisions-page" mb={8} w="30%">
              <Tab
                className="tab-groups"
                _selected={{
                  borderBottom: '3px solid',
                  borderColor: 'var(--MAIN-GREY)',
                }}
                _focus={{ outline: 'none' }}
                flex="1"
              >
                {t('group.tabs.joined')}
              </Tab>
              <Tab
                className="tab-groups"
                _selected={{
                  borderBottom: '3px solid',
                  borderColor: 'var(--MAIN-GREY)',
                }}
                _focus={{ outline: 'none' }}
                flex="1"
              >
                {t('group.tabs.hosting')}
              </Tab>
              <Tab
                className="tab-groups"
                _selected={{
                  borderBottom: '3px solid',
                  borderColor: 'var(--MAIN-GREY)',
                }}
                _focus={{ outline: 'none' }}
                flex="1"
              >
                {t('group.tabs.invited')}
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel p={0}>
                {/* Joined Groups */}
                <Groups
                  groups={groupsJoined.groups}
                  totalGroups={groupsJoined.totalGroups}
                  fetchData={fetchJoinedGroups}
                />
              </TabPanel>
              <TabPanel p={0}>
                {/* Hosted Groups */}
                <Groups
                  groups={groupsHosted.groups}
                  totalGroups={groupsHosted.totalGroups}
                  fetchData={fetchHostedGroups}
                />
              </TabPanel>
              <TabPanel p={0}>
                {/* Invited Groups */}
                <Groups
                  groups={groupsInvitations.invitations}
                  totalGroups={groupsInvitations.totalInvitations}
                  fetchData={fetchGroupsInvitations}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </>
  );
}
