import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../Sidebar';
import { Box, Flex } from '@chakra-ui/react';

const SidebarLayout = ({
  isOpen,
  onClose,
  changeBetweenLightAndDarkMode,
  lightMode,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Flex height="100%" position="relative">
      <Sidebar
        isOpen={isOpen}
        onClose={onClose}
        lightMode={lightMode}
        changeBetweenLightAndDarkMode={changeBetweenLightAndDarkMode}
      />
      <Box flex="1">
        <Outlet />
      </Box>
      {isOpen && !(windowWidth >= 768) && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100vw"
          height="100vh"
          backgroundColor="rgba(0, 0, 0, 0.5)"
          zIndex={4}
          onClick={onClose}
        />
      )}
    </Flex>
  );
};

export default SidebarLayout;
