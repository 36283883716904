import { formatDateToDDMMYYYY } from "./time";

export const normalizeGroup = (group) => {
    return {
        type: group.type,
        id: group.id,
        links: group.links,
        title: group.attributes.title,
        description: group.attributes.info,
        createdAt: group.attributes['created-at'],
        decisions: group.attributes['decisions-count'] ?? 0,
        openDecisions: group.attributes['decisions-open-count'],
        updatedAt: group.attributes['updated-at'],
        language: group.attributes.language,
        logoUrl: group.attributes['logo-url'],
        protected: group.attributes.protected,
        slug: group.attributes.slug,
        timeZone: group.attributes['time-zone'],
        members: group.attributes['user-count'],
        url: group.attributes.url,
    }
}

export const normalizeInvites = (response) => {
    return response.map((item) => {
        return {
            createdAt: item.attributes['created-at'],
            updatedAt: item.attributes['updated-at'],
            type: item.type,
            declined: item.attributes['declined-at'],
            status: item.attributes.status,
            links: item.links,
            id: item.id,
            lastEmail: item.attributes['last-email-at'] ? formatDateToDDMMYYYY(item.attributes['last-email-at'])
                : '',
            group: {
                id: item.relationships.group.data.id ?? 'None',
                decisionId: item.relationships.decision.data?.id ?? 'None',
            },
            user: {
                name: item.attributes.name,
                roles: item.attributes.roles.join(', '),
                email: item.attributes.email ?? 'None',
                publicAddress: item.attributes['public-address'] ?? 'None',
            }
        }
    })
}

export const normalizeUser = (user) => {
    return {
        type: user.type,
        id: user.id,
        links: user.links,
        email: user.attributes.email ?? 'None',
        name: user.attributes.name,
        publicAddress: user.attributes['public-address'] ?? 'None',
        isStaff: user.attributes['is-staff'],
        notifications: user.attributes.notifications,
        avatar: user.attributes.avatar,
        uploadKey: user.attributes['upload-key'],
    }
}

export const normalizeGroups = (response) => {
    const data = response.data || [];
    const included = response.included || [];

    // Ensure both arrays have the same length
    if (data.length !== included.length) {
        console.warn('Data and included arrays have different lengths');
        return included;
    }
    return included.map((item) => {
        const user = data.find((e) => item.id === e.relationships.group.data.id);
        return {
            id: item.id,
            type: item.type,
            title: item.attributes.title,
            description: item.attributes.info,
            roles: user.attributes.roles,
            isOwner: user.attributes['is-owner'],
            createdAt: item.attributes['created-at'],
            decisions: item.attributes['decisions-count'] ?? 0,
            openDecisions: item.attributes['decisions-open-count'],
            lastVisit: user.attributes['last-visit-at'],
            updatedAt: item.attributes['updated-at'],
            links: item.links,
            language: item.attributes.language,
            logoUrl: item.attributes['logo-url'],
            protected: item.attributes.protected,
            slug: item.attributes.slug,
            timeZone: item.attributes['time-zone'],
            members: item.attributes['user-count'],
            url: item.attributes.url,
            decisionId: user.relationships?.decision?.data?.id || null,
        }
    })
}

export const normalizeGroupUsers = (response) => {
    const data = response.data || [];
    const included = response.included || [];

    // Ensure both arrays have the same length
    if (data.length !== included.length) {
        console.warn('Data and included arrays have different lengths');
        return included;
    }

    return included.map((item) => {
        const group = data.find((e) => item.id === e.relationships.user.data.id);
        return {
            groupUserId: group.id,
            id: item.id,
            type: item.type,
            avatar: item.attributes.avatar,
            isStaff: item.attributes['is-staff'],
            name: item.attributes.name,
            publicAddress: item.attributes['public-address'] ?? 'None',
            email: item.attributes.email ?? 'None',
            roles: group.attributes.roles.join(', '),
            createdAt: group.attributes['created-at'] ? formatDateToDDMMYYYY(group.attributes['created-at'])
                : 'None',
            lastVisit: group.attributes['last-visit-at'] ? formatDateToDDMMYYYY(group.attributes['last-visit-at'])
                : 'None',
            group: {
                isOwner: group.attributes['is-owner'],
                id: group.relationships?.group?.data?.id || null,
                decisionId: group.relationships?.decision?.data?.id || null,
                links: group.links,
                decisions: group.attributes['decisions-count'] ?? 0,
                updatedAt: group.attributes['updated-at'],
            },
        }
    })
}

export const normalizeDecisions = (response) => {
    return response.map((item) => {
        return {
            id: item.id,
            type: item.type,
            title: item.attributes.title,
            updatedAt: item.attributes['updated-at'],
            description: item.attributes.info,
            language: item.attributes.language,
            openAt: item.attributes['open-at'],
            closeAt: item.attributes['close-at'],
            timeZone: item.attributes['time-zone'],
            links: item.links,
            groupId: item.relationships?.group?.data?.id || null,
            decisionId: item.attributes['decision-id'],
            image: item.attributes['image-url'],
            currentMode: item.attributes['current-mode'],
            requireWeb3: item.attributes['require-web3'],
            subdomain: item.attributes.subdomain,
        }
    })
}

export const normalizeDecisionsTemplates = (response) => {
    return response.map((item) => {
        return {
            id: item.id,
            type: item.type,
            title: item.attributes.title,
            subdomain: item.attributes.subdomain,
            links: item.links,
            language: item.attributes.language,
            description: item.attributes.info,
            image: item.attributes.image,
            decisionId: item.attributes['decision-id'],
        }
    })
}

export const normalizeRoleDecisions = (response) => {
    return response.map((item) => {
        return {
            invited: item.invited,
            id: item.id,
            type: item.type,
            links: item.links,
            closeAt: item.attributes['close-at'],
            openAt: item.attributes['open-at'],
            updatedAt: item.attributes['updated-at'],
            title: item.attributes.title,
            timeZone: item.attributes['time-zone'],
            requireWeb3: item.attributes['require-web3'],
            description: item.attributes.info,
            language: item.attributes.language,
            image: item.attributes['image-url'],
            group: {
                decisionId: item.attributes['decision-id'],
                title: item.attributes['group-name'],
                roles: item.attributes.roles,
            },
            currentMode: item.attributes['current-mode'],
            subdomain: item.attributes.subdomain,
            completion: item.attributes.completion,
            country: item.attributes.country,
            location: item.attributes.location,
            published: item.attributes.published,

        }
    })
}