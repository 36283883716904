import React, { useState } from 'react';
import {
  Card,
  Box,
  Button,
  Text,
  Flex,
  Link,
  useDisclosure,
  Image,
  Tooltip,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';
import { timeDifference, formatDate } from 'utils/time';
import standardImage from 'assets/images/standard_image.png';
import ModalGroupsCardDecision from 'components/ModalGroupsCardDecision';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const GroupsCardDecision = ({ currentDecision, isAdmin }) => {
  const { t } = useMarkdownTranslations();
  const [isError, setIsError] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getElapsedTimePercentage = (startDate, endDate) => {
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    const now = Date.now();

    if (start > end) {
      throw new Error('Start date must be before end date.');
    }

    if (now <= start) {
      return 0;
    }

    if (now >= end) {
      return 100;
    }

    const totalDuration = end - start;
    const elapsed = now - start;
    return Math.floor((elapsed / totalDuration) * 100);
  };

  return (
    <>
      {/* Render the modal and pass the necessary props */}
      <ModalGroupsCardDecision
        isOpen={isOpen}
        onClose={onClose}
        currentDecision={currentDecision}
        isAdmin={isAdmin}
      />
      <Card className="card-decision-card-host">
        <Box className="box-image-decision-card-host">
          <Image
            className="image-decision-card-host"
            src={
              isError ? standardImage : currentDecision.image || standardImage
            }
            alt={t('decision')}
            onError={() => setIsError(true)}
          />
        </Box>
        <Text className="title-decision-card-host">
          {currentDecision.title}
        </Text>
        <Link
          href={
            currentDecision.currentMode === 'Unpublished'
              ? currentDecision.links?.preview
              : currentDecision.links?.visit
          }
          target="_blank"
        >
          {currentDecision.currentMode === 'Unpublished'
            ? currentDecision.links?.preview
            : currentDecision.links?.visit}
        </Link>
        <Flex direction="row" gap={2}>
          {currentDecision.currentMode === 'open' &&
            currentDecision.openAt &&
            currentDecision.closeAt && (
              <Link href={currentDecision.links?.visit}>
                <Button className="special-link-button-decision-card-host">
                  <FontAwesomeIcon
                    icon="fa-regular fa-moon"
                    className="icon-button-decision-card-host"
                  />
                  {t('btn.completed_percentage', {
                    percentage: getElapsedTimePercentage(
                      currentDecision.openAt,
                      currentDecision.closeAt,
                    ),
                  })}
                </Button>
              </Link>
            )}

          {currentDecision.currentMode?.toLowerCase().includes('finished') && (
            <Link href={currentDecision.links?.results}>
              <Button className="special-link-button-decision-card-host">
                <FontAwesomeIcon
                  icon={faCheck}
                  className="icon-button-decision-card-host"
                />
                {t('btn.view_results')}
              </Button>
            </Link>
          )}

          {currentDecision.currentMode
            ?.toLowerCase()
            .includes('unpublished') && (
            <Link href={currentDecision.links?.results}>
              <Button className="special-link-button-decision-card-host">
                <FontAwesomeIcon
                  icon="fa-regular fa-moon"
                  className="icon-button-decision-card-host"
                />
                {t('btn.unpublished')}
              </Button>
            </Link>
          )}
        </Flex>
        <Flex direction="column">
          <Flex className="date-flex-decision-card-host">
            <Text className="date-tag-decision-card-host">
              {t('decision.details.opens_on')}:
            </Text>
            <Text ml={0} className="date-text-decision-card-host">
              {currentDecision.openAt ? (
                <>
                  {formatDate(currentDecision.openAt)} (
                  {timeDifference(currentDecision.openAt)} )
                </>
              ) : (
                <>{t('decision.details.to_be_determined')}</>
              )}
            </Text>
          </Flex>
          <Flex className="date-flex-decision-card-host">
            <Text className="date-tag-decision-card-host">
              {t('decision.details.closes')}:
            </Text>
            <Text ml={0} className="date-text-decision-card-host">
              {currentDecision.closeAt ? (
                <>
                  {formatDate(currentDecision.closeAt)} (
                  {timeDifference(currentDecision.closeAt)} )
                </>
              ) : (
                <>{t('decision.details.to_be_determined')}</>
              )}
            </Text>
          </Flex>
        </Flex>

        {isAdmin && (
          <Flex
            className="flex-buttons-decision-card-host"
            gap={2}
            direction="row"
          >
            {currentDecision.links?.visit && (
              <Tooltip label={t('tooltip.visit')}>
                <Link
                  href={currentDecision.links?.visit}
                  target="_blank"
                  width="100%"
                >
                  <Button className="icon-bottom-button-decision-card-host">
                    <FontAwesomeIcon
                      icon="fa-solid fa-eye"
                      className="icon-decision-card-host"
                    />
                  </Button>
                </Link>
              </Tooltip>
            )}
            {currentDecision.links?.edit && (
              <Tooltip label={t('tooltip.edit')}>
                <Link
                  href={currentDecision.links?.edit}
                  target="_blank"
                  width="100%"
                >
                  <Button className="icon-bottom-button-decision-card-host">
                    <FontAwesomeIcon
                      icon="fa-solid fa-pen"
                      className="icon-decision-card-host"
                    />
                  </Button>
                </Link>
              </Tooltip>
            )}
            {currentDecision.links?.analysis && (
              <Tooltip label={t('tooltip.analysis')}>
                <Link
                  href={currentDecision.links?.analysis}
                  target="_blank"
                  width="100%"
                >
                  <Button className="icon-bottom-button-decision-card-host">
                    <FontAwesomeIcon
                      icon="fa-solid fa-chart-simple"
                      className="icon-decision-card-host"
                    />
                  </Button>
                </Link>
              </Tooltip>
            )}

            <Tooltip label={t('tooltip.invite')}>
              <Button
                className="icon-bottom-button-decision-card-host"
                width="100%"
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-user-plus"
                  className="icon-decision-card-host"
                />
              </Button>
            </Tooltip>

            {currentDecision.links?.comments && (
              <Tooltip label={t('tooltip.comments')}>
                <Link
                  href={currentDecision.links?.comments}
                  target="_blank"
                  width="100%"
                >
                  <Button className="icon-bottom-button-decision-card-host">
                    <FontAwesomeIcon
                      icon="fa-solid fa-comment"
                      className="icon-decision-card-host"
                    />
                  </Button>
                </Link>
              </Tooltip>
            )}
          </Flex>
        )}

        <Button
          className="button-details-decision-card-host"
          rightIcon={<FontAwesomeIcon icon="fa-solid fa-chevron-down" />}
          justifyContent="space-between"
          onClick={onOpen} // Open the modal when clicked
        >
          {t('btn.show_details')}
        </Button>
      </Card>
    </>
  );
};

export default GroupsCardDecision;
