import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {
  Spinner,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import PaymentForm from '../PaymentForm';

const publicKeyStripe = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

const stripe = loadStripe(publicKeyStripe);

const StripePayment = ({
  isOpen,
  onClose,
  paymentInformation,
  paymentDescription,
  paymentAmount,
  clientSecret,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{paymentInformation}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box display="flex" justifyContent="center" alignItems="center">
            {clientSecret ? (
              <Elements
                stripe={stripe}
                options={{ clientSecret, theme: 'stripe' }}
              >
                <PaymentForm />
              </Elements>
            ) : (
              <Spinner size="xl" />
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default StripePayment;
