import React, { useEffect } from 'react';
import LoginWithoutMetamaskInstalled from 'components/LoginWithoutMetamaskInstalled';
import isMetaMaskInstalled from 'features/web3/utils/isMetaMaskInstalled';
import { useState } from 'react';
import { Box } from '@chakra-ui/react';

export default function Login() {
  const hasMetamaskExtension = isMetaMaskInstalled();
  const [loginWithMetamask, setLoginWithMetamask] =
    useState(hasMetamaskExtension);

  useEffect(() => {
    setLoginWithMetamask(hasMetamaskExtension);
  }, [hasMetamaskExtension]);

  return (
    <Box id="main">
      <LoginWithoutMetamaskInstalled
        setLoginWithMetamask={setLoginWithMetamask}
      />
    </Box>
  );
}
