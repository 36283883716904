const colorPalette = [
  'rgba(255, 0, 0, 0.5)', // red
  'rgba(0, 0, 255, 0.5)', // blue
  'rgba(0, 128, 0, 0.5)', // green
  'rgba(128, 0, 128, 0.5)', // purple
  'rgba(255, 165, 0, 0.5)', // orange
  'rgba(128, 128, 128, 0.5)', // gray
  'rgba(0, 255, 255, 0.5)', // cyan
  'rgba(0, 128, 128, 0.5)', // teal
  'rgba(255, 192, 203, 0.5)', // pink
];

// Generate a index from a string
const hashString = (str) => {
  return str.split('').reduce((hash, char) => {
    return char.charCodeAt(0) + ((hash << 5) - hash);
  }, 0);
};

// Pick a color from the palette based on the group title
export const pickPalette = (groupTitle) => {
  if (!groupTitle) return colorPalette[0];

  const index = Math.abs(hashString(groupTitle));
  const id = index % colorPalette.length;
  return colorPalette[id];
};
