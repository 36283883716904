import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Flex,
  Stack,
  CardBody,
  Text,
  Button,
  Avatar,
  Image,
} from '@chakra-ui/react';
import './styles.css';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';
import PaymentItems from 'components/PaymentItems';
import standardImage from '../../assets/images/standard_image.png';
import WelcomeBanner from 'components/WelcomeBanner';

export default function HomeDashboard() {
  const { t, tMarkdown } = useMarkdownTranslations();
  const [isFirstLogin, setIsFirstLogin] = useState(false);

  useEffect(() => {
    const firstLogin = sessionStorage.getItem('first_login');
    if (firstLogin === 'true') {
      setIsFirstLogin(true);
    }
  }, []);

  return (
    <>
      <Box className="main-box-home-dashboard">
        <WelcomeBanner isFirstLogin={isFirstLogin} />
        <Card className="info-card-home-dashboard">
          <Text className="info-text-header-home-dashboard">
            {t('home.quick_guide.header')}
          </Text>
          <Box className="info-text-body-home-dashboard">
            {tMarkdown('home.quick_guide.description_html')}
          </Box>
          <Card className="secondary-info-card-home-dashboard">
            <Text className="secondary-info-card-header-text-home-dashboard">
              {t('home.participate.header')}
            </Text>
            <Box className="secondary-info-card-body-text-home-dashboard">
              {tMarkdown('home.participate.description_html')}
            </Box>
          </Card>
          <Card className="secondary-info-card-home-dashboard">
            <Text className="secondary-info-card-header-text-home-dashboard">
              {t('home.host.header')}
            </Text>
            <Box className="secondary-info-card-body-text-home-dashboard">
              {tMarkdown('home.host.description_html')}
            </Box>
          </Card>
          <Card className="secondary-info-card-home-dashboard">
            <Text className="secondary-info-card-header-text-home-dashboard">
              {t('home.create_decision.header')}
            </Text>
            <Box className="secondary-info-card-body-text-home-dashboard">
              {tMarkdown('home.create_decision.description_html')}
            </Box>
          </Card>
        </Card>
        <Card className="products-card-home-dashboard">
          <Box className="products-card-header-text-home-dashboard">
            {tMarkdown('home.products.header')}
          </Box>
          <Box className="products-card-body-text-home-dashboard">
            {tMarkdown('home.products.description_html')}
          </Box>
          <PaymentItems />
        </Card>
        <Card className="bottom-card-home-dashboard">
          <Text className="bottom-card-header-text-home-dashboard">
            {t('home.latest_updates.header')}
          </Text>
          <Box className="bottom-card-body-text-home-dashboard">
            {tMarkdown('home.latest_updates.description_html')}
          </Box>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            gap={8}
            wrap="wrap"
            className="flex-bottom-cards-home-dashboard"
          >
            {/* This is sample info */}
            <Card
              flex={{ base: 'initial', md: '1' }}
              className="card-bottom-home-dashboard"
            >
              <Box className="box-image-home-dashboard">
                <Image src={standardImage} alt={t('home.news')} width="100%" />
                <Button className="date-button-home-dashboard">
                  Oct. 15 2024
                </Button>
              </Box>
              <Text className="card-bottom-header-text-home-dashboard">
                Update
              </Text>
              <Text className="card-bottom-body-text-home-dashboard">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </Text>
            </Card>

            <Card
              flex={{ base: 'initial', md: '1' }}
              className="card-bottom-home-dashboard"
            >
              <Box className="box-image-home-dashboard">
                <Image src={standardImage} alt="News" width="100%" />
                <Button className="date-button-home-dashboard">
                  Oct. 15 2024
                </Button>
              </Box>
              <Text className="card-bottom-header-text-home-dashboard">
                Update
              </Text>
              <Text className="card-bottom-body-text-home-dashboard">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </Text>
            </Card>

            <Card
              flex={{ base: 'initial', md: '1' }}
              className="card-bottom-home-dashboard"
            >
              <Box className="box-image-home-dashboard">
                <Image src={standardImage} alt="News" width="100%" />
                <Button className="date-button-home-dashboard">
                  Oct. 15 2024
                </Button>
              </Box>
              <Text className="card-bottom-header-text-home-dashboard">
                Update
              </Text>
              <Text className="card-bottom-body-text-home-dashboard">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </Text>
            </Card>
          </Flex>
        </Card>
      </Box>
    </>
  );
}
